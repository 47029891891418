import React, {
    BaseSyntheticEvent,
    forwardRef,
    MouseEvent,
    useEffect,
    useRef,
    useMemo,
    memo,
} from 'react';
import {
    Column,
    Row,
    usePagination,
    useRowSelect,
    useSortBy,
    useTable,
    useFlexLayout,
    SortingRule,
    CellProps,
    TableToggleAllRowsSelectedProps,
} from 'react-table';
import { useTranslation } from 'react-i18next';

import {
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    Typography,
    TableContainer,
} from '@mui/material';

import { CircularProgress, Pagination } from 'src/components/base';

import {
    useClasses,
    useTableBodyStyle,
    useTableRowStyle,
    useTableStyle,
    useCustomTableStyles,
    useTableContainerStyles,
    useTableHeaderStyle,
} from './custom-table-styles';
import { ReactElement } from 'react';

interface CustomTableProps {
    columns: ReadonlyArray<Column<object>>;
    data: object[];
    isLoading: boolean;
    tableBodyClassName?: string;
    noDataMessage?: string;
    onRowClick?: (e: MouseEvent<HTMLTableRowElement>, value: Row<object>) => void;
    tableHeaderClassName?: string;
    languageNamespaces?: string[];
    handleSort?: (sortBy?: SortingRule<string>) => void;
    sortField?: string | null;
    sortDesc?: boolean | null;
    selectionEnabled?: boolean;
    handleRemoveAllRows?: () => void;
    removeRowById?: (id: string) => void;
    activeRow?: string;
    onlyOneRowSelection?: boolean;
    handleRowSelection?: (value: (Row<object> | undefined)[]) => void;
    getRowId?: (row: object) => string;
    selectedRows?: string[];
    withPagination?: boolean;
    onChangePage?: (event: MouseEvent<HTMLButtonElement> | null, page: number) => void;
    handleChangeRowsPerPage?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
    count?: number;
    page?: number;
    rowsPerPage?: number;
    hiddenColumns?: string[];
}

type IndeterminateCheckboxProps = {
    indeterminate?: boolean;
    onClick?: () => void;
};

const IndeterminateCheckbox = forwardRef<HTMLButtonElement, IndeterminateCheckboxProps>(
    ({ indeterminate, ...rest }, ref) => {
        const classes = useClasses();
        const defaultRef = useRef<HTMLButtonElement>(null);
        const resolvedRef = ref || defaultRef;

        useEffect(() => {
            if (typeof resolvedRef === 'object' && resolvedRef.current) {
                (resolvedRef.current as IndeterminateCheckboxProps).indeterminate =
                    Boolean(indeterminate);
            }
        }, [resolvedRef, indeterminate]);

        const stopPropagation = (e: BaseSyntheticEvent): void => {
            e.stopPropagation();
        };

        return (
            <>
                <Checkbox
                    classes={classes}
                    disableRipple
                    ref={resolvedRef}
                    onClick={stopPropagation}
                    {...rest}
                />
            </>
        );
    }
);

IndeterminateCheckbox.displayName = 'IndeterminateCheckbox';

const MemoizedIndeterminateCheckbox = memo(
    ({ row }: CellProps<object>): ReactElement | null => {
        const props = row.getToggleRowSelectedProps();
        const rowOriginal = row.original as { notSelectable: string };
        if (rowOriginal.notSelectable) {
            return null;
        }
        return <IndeterminateCheckbox {...props} />;
    },
    (prevProps, nextProps) => {
        const prevPropsSelectedRowIds = Object.keys(prevProps.state.selectedRowIds);
        const nextPropsSelectedRowIds = Object.keys(nextProps.state.selectedRowIds);
        const rowId = (prevProps.row.original as { id: string }).id;
        const isValueChanged =
            prevPropsSelectedRowIds.some((item) => item === rowId) !==
            nextPropsSelectedRowIds.some((item) => item === rowId);
        return !isValueChanged;
    }
);

MemoizedIndeterminateCheckbox.displayName = 'MemoizedIndeterminateCheckbox';

export function CustomTable({
    columns,
    data,
    isLoading,
    tableHeaderClassName,
    tableBodyClassName,
    noDataMessage,
    onRowClick,
    languageNamespaces,
    handleSort,
    activeRow,
    handleRowSelection,
    getRowId,
    withPagination,
    onChangePage,
    handleChangeRowsPerPage,
    count,
    page,
    rowsPerPage,
    selectionEnabled,
    sortField,
    sortDesc,
    selectedRows,
    hiddenColumns,
}: CustomTableProps): JSX.Element {
    const classes = useCustomTableStyles();
    const tableHeaderClasses = useTableHeaderStyle();
    const tableClasses = useTableStyle();
    const tableContainerClasses = useTableContainerStyles();
    const tableBodyClasses = useTableBodyStyle({
        isRowClickable: Boolean(onRowClick && Array.isArray(data) && data.length > 0) && !isLoading,
    });
    const tableRowClasses = useTableRowStyle();

    const { t } = useTranslation(languageNamespaces);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        rows,
        state: { sortBy, selectedRowIds },
    } = useTable(
        {
            columns,
            data,
            getRowId,
            manualSortBy: true,
            useControlledState: (state) => {
                return useMemo(() => {
                    return {
                        ...state,
                        hiddenColumns: hiddenColumns || state.hiddenColumns,
                        pageIndex: page || state.pageIndex,
                        pageSize: rowsPerPage || state.pageSize,
                    };
                }, [state, page, rowsPerPage, hiddenColumns]);
            },
            initialState: {
                hiddenColumns,
                pageIndex: page,
                pageSize: rowsPerPage,
                sortBy:
                    sortField && typeof sortDesc === 'boolean'
                        ? [{ id: sortField, desc: sortDesc }]
                        : [],
                selectedRowIds: Array.isArray(selectedRows)
                    ? selectedRows.reduce((acc, item) => {
                          return { ...acc, [item]: true };
                      }, {})
                    : [],
            },
        },
        useSortBy,
        usePagination,
        useRowSelect,
        useFlexLayout,

        (hooks) => {
            if (selectionEnabled) {
                hooks.visibleColumns.push((columns) => [
                    {
                        id: 'selection',
                        Header: ({
                            getToggleAllPageRowsSelectedProps,
                        }: {
                            getToggleAllPageRowsSelectedProps: (
                                props?: Partial<TableToggleAllRowsSelectedProps>
                            ) => TableToggleAllRowsSelectedProps;
                        }) => <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />,
                        Cell: MemoizedIndeterminateCheckbox,
                        width: 30,
                        disableSortBy: true,
                    },
                    ...columns,
                ]);
            }
        }
    );

    useEffect(() => {
        if (Array.isArray(sortBy) && typeof handleSort === 'function') {
            handleSort(sortBy[0]);
        }
    }, [sortBy, handleSort]);

    useEffect(() => {
        const rowsData = Object.keys(selectedRowIds)
            .map((rowId) => rows.find((row) => row.id === rowId))
            .filter((row) => Boolean(row));
        if (Array.isArray(rowsData) && typeof handleRowSelection === 'function') {
            handleRowSelection(rowsData);
        }
    }, [JSON.stringify(selectedRowIds)]);

    return (
        <TableContainer classes={tableContainerClasses}>
            <Table classes={tableClasses} {...getTableProps()}>
                <TableHead classes={tableHeaderClasses} className={tableHeaderClassName}>
                    {headerGroups.map((headerGroup) => {
                        const headerRowProps = headerGroup.getHeaderGroupProps();
                        return (
                            <TableRow
                                {...headerRowProps}
                                key={headerRowProps.key}
                                classes={tableRowClasses}
                            >
                                {headerGroup.headers.map((column) => {
                                    if (column.disableSortBy) {
                                        return (
                                            <TableCell
                                                {...column.getHeaderProps(
                                                    column.getSortByToggleProps({
                                                        title: t('common:sorting'),
                                                    })
                                                )}
                                                key={column.id}
                                            >
                                                {column.render('Header')}
                                            </TableCell>
                                        );
                                    }
                                    return (
                                        <TableCell
                                            {...column.getHeaderProps(
                                                column.getSortByToggleProps({
                                                    title: t('common:sorting'),
                                                })
                                            )}
                                            key={column.id}
                                        >
                                            <TableSortLabel
                                                style={{ height: '100%', display: 'flex' }}
                                                active={column.isSorted}
                                                direction={
                                                    column.isSorted
                                                        ? column.isSortedDesc
                                                            ? 'desc'
                                                            : 'asc'
                                                        : 'asc'
                                                }
                                            >
                                                {column.render('Header')}
                                            </TableSortLabel>
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        );
                    })}
                </TableHead>
                <TableBody
                    classes={tableBodyClasses}
                    className={tableBodyClassName}
                    {...getTableBodyProps()}
                >
                    {!isLoading && (!Array.isArray(data) || data.length === 0) && noDataMessage && (
                        <TableRow className={classes.noDataRow}>
                            <TableCell align='center'>
                                <Typography variant='body2'>{t(noDataMessage)}</Typography>
                            </TableCell>
                        </TableRow>
                    )}
                    {isLoading && (
                        <TableRow className={classes.noDataRow}>
                            <TableCell>
                                <CircularProgress isLoading={isLoading} />
                            </TableCell>
                        </TableRow>
                    )}
                    {!isLoading &&
                        rows.map((row) => {
                            prepareRow(row);
                            const rowOriginal = row.original as {
                                id: string;
                                notSelectable: boolean;
                            };
                            return (
                                <TableRow
                                    {...row.getRowProps()}
                                    classes={tableRowClasses}
                                    className={
                                        (row.original as { id: string }).id === activeRow
                                            ? classes.activeRow
                                            : ''
                                    }
                                    key={row.id}
                                    onClick={
                                        onRowClick && !rowOriginal.notSelectable
                                            ? (e) => onRowClick(e, row)
                                            : undefined
                                    }
                                >
                                    {row.cells.map((cell) => {
                                        return (
                                            <TableCell
                                                {...cell.getCellProps()}
                                                key={cell.column.id + cell.row.id}
                                            >
                                                {cell.render('Cell')}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        })}
                </TableBody>
            </Table>
            {withPagination && onChangePage && handleChangeRowsPerPage && (
                <Pagination
                    count={count || 0}
                    page={page || 0}
                    rowsPerPage={rowsPerPage || 10}
                    onChangePage={onChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                />
            )}
        </TableContainer>
    );
}

CustomTable.defaultProps = {
    selectionEnabled: false,
    hiddenColumns: [],
};
