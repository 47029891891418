import { useEffect } from 'react';
import { AxiosRequestConfig } from 'axios';

import { isDevelopment } from 'src/environment';
import { useAxios } from 'src/lib/http-client/axios-http-infrastructure';

export const usePathInterceptor = (): void => {
    const axios = useAxios();

    useEffect(() => {
        const requestInterceptor = (config: AxiosRequestConfig): AxiosRequestConfig => {
            config.baseURL = isDevelopment ? config.baseURL : window.location.origin;
            config.url = !config.url?.startsWith('api') ? `api/${config.url}` : config.url;
            return config;
        };

        axios.interceptors.request.use(requestInterceptor);
    }, []);
};
