import { useEffect, useState, MutableRefObject } from 'react';

type UseOverflowActiveProps = {
    ref: MutableRefObject<HTMLElement | undefined>;
    label: string;
};

export const useOverflowActive = ({ ref, label }: UseOverflowActiveProps): boolean => {
    const [isEllipsisActive, setElipsis] = useState(false);

    useEffect(() => {
        if (ref.current && label) {
            setElipsis(ref.current?.offsetWidth < ref.current?.scrollWidth);
        }
    }, [ref, label]);

    return isEllipsisActive;
};
