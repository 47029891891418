import { useReducer, ReactElement } from 'react';

import { GlobalStoreContext, defaultStore } from './global-store';
import { globalStoreReducer } from './global-store-reducer';
interface GlobalStoreProviderProps {
    children: ReactElement | ReactElement[];
}

export const GlobalStoreProvider = ({ children }: GlobalStoreProviderProps): ReactElement => {
    const [store, dispatch] = useReducer(globalStoreReducer, defaultStore.store);
    return (
        <GlobalStoreContext.Provider value={{ store, dispatch }}>
            {children}
        </GlobalStoreContext.Provider>
    );
};
