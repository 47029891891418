import React from 'react';
import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useDarkMode from 'use-dark-mode';

import { Switcher } from 'src/components/base';

import { HeaderProfileMenu } from './header-profile-menu';
import { HeaderLogo } from './header-get-logo';
import { useHeaderStyle } from './header-styles';

export const Header = (): JSX.Element => {
    const darkMode = useDarkMode();
    const { t } = useTranslation(['common']);

    const classes = useHeaderStyle();

    return (
        <Grid container component='header' className={classes.header}>
            <Grid item className={classes.logoContainer}>
                <Box mr={4}>
                    <HeaderLogo />
                </Box>
            </Grid>
            <div style={{ flexGrow: 1 }} />
            <Grid item className={classes.switcherContainer}>
                <Switcher
                    leftLabel={t('common:light')}
                    rightLabel={t('common:dark')}
                    checked={darkMode.value}
                    onChange={darkMode.toggle}
                />
            </Grid>
            <HeaderProfileMenu />
        </Grid>
    );
};
