import newGuid from '../../../lib/utility/guid';
import XmlAttribute from './xml-attribute';
import XmlTree from './xml-tree';

export default class XmlElement {
    tree: XmlTree;
    id: string;
    attributes: XmlAttribute[];
    children: XmlElement[];
    parent?: XmlElement;
    name: string;
    value?: string;

    constructor(element: Element, tree: XmlTree, parent?: XmlElement) {
        this.tree = tree;
        this.id = newGuid();
        this.tree.elements.set(this.id, this);
        this.attributes = [];
        for (let i = 0; i < element.attributes.length; i++) {
            const rawAttribute = element.attributes.item(i)!;
            const attribute = new XmlAttribute(rawAttribute.name, rawAttribute.value);
            this.attributes.push(attribute);
        }

        this.children = [];
        for (let i = 0; i < element.children.length; i++) {
            const rawNode = element.children.item(i)!;
            const node = new XmlElement(rawNode, tree, this);
            this.children.push(node);
        }

        this.name = element.nodeName;
        this.parent = parent;

        if (element.childNodes.length > 0 && element.children.length === 0) {
            this.value = element.childNodes.item(0)!.nodeValue!;
        } else {
            this.value = element.nodeValue!;
        }
    }

    toString(): string {
        let result = '<';
        result += this.name;
        for (let i = 0; i < this.attributes.length; i++) {
            result += ' ';
            result += this.attributes.at(i)!.key;
            result += '="';
            result += this.attributes.at(i)!.value;
            result += '"';
        }
        result += '>';
        if (this.value !== null) {
            result += this.value;
        } else {
            for (let i = 0; i < this.children.length; i++) {
                result += this.children.at(i)!.toString();
            }
        }
        result += '</';
        result += this.name;
        result += '>';
        return result;
    }
}
