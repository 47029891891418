import { useState } from 'react';

import { TableColumnSettings } from 'src/shared/types';
import { TableNames } from 'src/shared/constants';
import { useHttpClient } from 'src/lib/http-client/use-http-client';

interface UseUpdateTableSettingsReturn {
    isLoading: boolean;
    updateTableSettings: (settings: Omit<TableColumnSettings, 'position'>[]) => Promise<void>;
}

interface UseUpdateTableSettingsParams {
    tableName: TableNames;
    onError: () => void;
    onSuccess?: () => void;
}

export const useUpdateTableSettings = ({
    tableName,
    onError,
    onSuccess,
}: UseUpdateTableSettingsParams): UseUpdateTableSettingsReturn => {
    const httpClient = useHttpClient();
    const [isLoading, setLoading] = useState<boolean>(false);

    const updateTableSettings = async (
        columns: Omit<TableColumnSettings, 'position'>[]
    ): Promise<void> => {
        try {
            setLoading(true);
            const requestBody: { columns: Omit<TableColumnSettings, 'position'>[] } = {
                columns,
            };
            await httpClient.put(`tables/${tableName}/table-settings`, requestBody);
            if (onSuccess) {
                onSuccess();
            }
        } catch {
            onError();
        } finally {
            setLoading(false);
        }
    };

    return { isLoading, updateTableSettings };
};
