import { ChangeTableKeys } from './change-table-config';

export const changeTableDefaultOrderColumn = {
    [ChangeTableKeys.insuranceConfirmationNumber]: 0,
    [ChangeTableKeys.licenseNumber]: 1,
    [ChangeTableKeys.date]: 2,
    [ChangeTableKeys.startOfContract]: 3,
    [ChangeTableKeys.owner]: 4,
    [ChangeTableKeys.postcode]: 5,
    [ChangeTableKeys.place]: 6,
    [ChangeTableKeys.street]: 7,
    [ChangeTableKeys.houseNumber]: 8,
    [ChangeTableKeys.agencyNumber]: 9,
};
