import { createContext, useContext } from 'react';
import { LoggedInUserDto } from './logged-in-user-dto';

export interface LoginContextProps {
    login: (email: string, password: string) => Promise<void>;
    logout: () => Promise<void>;
    user: LoggedInUserDto | null;
}

export const LoginContext = createContext<LoginContextProps | null>(null);

export const useLogin = (): LoginContextProps => {
    const loginContext = useContext(LoginContext);
    if (loginContext === null) {
        throw new Error(
            'LoginContext.Provider is not set in the React component tree. You should use LoginProvider as a parent component'
        );
    }

    return loginContext;
};
