import XmlElement from './xml-element';

export default class XmlTree {
    root: XmlElement;
    elements: Map<string, XmlElement>;

    constructor(rawXml: string) {
        const parser = new DOMParser();
        const obj = parser.parseFromString(rawXml, 'text/xml');
        this.elements = new Map<string, XmlElement>();
        this.root = new XmlElement(obj.documentElement, this);
    }

    toString(): string {
        return this.root.toString();
    }
}
