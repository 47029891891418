import React, { BaseSyntheticEvent, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { FormControl, OutlinedInput } from '@mui/material';

import { useFormControlStyles, useThemeSearchFieldInputStyles } from './search-styles';

interface SearchProps {
    value: string;
    handleChange: (name: string, value: string) => void;
    name: string;
    disabled: boolean;
}

export const Search = (props: SearchProps): ReactElement => {
    const formControlClasses = useFormControlStyles();
    const inputClasses = useThemeSearchFieldInputStyles();
    const { value, name, handleChange, disabled, ...rest } = props;
    const { t } = useTranslation(['common']);

    const onChange = (e: BaseSyntheticEvent): void => {
        handleChange(name, e.target.value);
    };

    return (
        <>
            <FormControl fullWidth classes={formControlClasses} variant='outlined'>
                <OutlinedInput
                    disabled={disabled}
                    placeholder={t('search')}
                    classes={inputClasses}
                    onChange={onChange}
                    value={value}
                    {...rest}
                />
            </FormControl>
        </>
    );
};
