import React, { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';

import { AuthLayout } from 'src/components/layouts';
import { LoginForm } from 'src/components/forms';
import { useLogin } from 'src/security/login-context';
import { AppRoutes } from 'src/routing/app-routes';

export const LoginPage = (): ReactElement => {
    const { user } = useLogin();
    const isAuth = Boolean(user);

    return (
        <AuthLayout>
            <LoginForm />
        </AuthLayout>
    );
};
