import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { baseUrl } from '../../environment';

function configureAxiosGlobalSettings(): void {
    axios.defaults.baseURL = baseUrl;
}

export function createAxiosInstance(): AxiosInstance {
    configureAxiosGlobalSettings();

    const configuration: AxiosRequestConfig = {};

    configuration.baseURL = baseUrl;

    return axios.create(configuration);
}
