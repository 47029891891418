import React, { ReactElement } from 'react';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useDarkMode from 'use-dark-mode';

import { Grid, Button, Typography, useTheme } from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

import { THEME_MODES } from 'src/theming/theme-modes';
import { Footer, Switcher } from 'src/components/base';

import { useAuthLayoutStyles } from './auth-layout-styles';

interface AuthLayoutProps {
    children: JSX.Element;
    backPath?: string;
}

export const AuthLayout = (props: AuthLayoutProps): ReactElement => {
    const { children, backPath } = props;
    const darkMode = useDarkMode();
    const navigate = useNavigate();
    const { t } = useTranslation(['common', 'login']);
    const classes = useAuthLayoutStyles();
    const theme = useTheme();

    const handleGoBack = (): void => {
        if (backPath) {
            navigate(backPath);
        }
    };

    const logoSrc =
        theme.palette.mode === THEME_MODES.DARK
            ? '/static/images/dark-theme-logo.png'
            : '/static/images/light-theme-logo.png';

    return (
        <div className={classes.pageContainer}>
            <div className={clsx(classes.block, classes.logoAndSwitcherContainer)}>
                <div className={classes.logoContainer}>
                    <img alt='logo' src={logoSrc} className={classes.logo} />
                </div>
                <div className={classes.switcherContainer}>
                    <Switcher
                        leftLabel={t('common:light')}
                        rightLabel={t('common:dark')}
                        checked={darkMode.value}
                        onChange={darkMode.toggle}
                    />
                </div>
            </div>
            <div className={clsx(classes.block, classes.previewContainer)} />
            <div>
                <Grid item>
                    {backPath && (
                        <Button
                            disableElevation
                            disableRipple
                            onClick={handleGoBack}
                            startIcon={<KeyboardBackspaceIcon />}
                        >
                            {t('common:back')}
                        </Button>
                    )}
                </Grid>
                <div style={{ flexGrow: 1 }} />
            </div>
            <div className={classes.childrenContainer}>
                <Typography variant='h3' className={classes.previewText}>
                    {t('login:portalDescription')}
                </Typography>
                <div className={classes.formContainer}>{children}</div>
            </div>
            <div style={{ flexGrow: 1 }} />
            <div className={classes.footerContainer}>
                <Footer />
            </div>
        </div>
    );
};
