import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

import { THEME_MODES } from 'src/theming/theme-modes';

export const usePageStyles = makeStyles((theme: Theme) => {
    const isLightMode = THEME_MODES.LIGHT === theme.palette.mode;
    return {
        pageContainer: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            paddingBottom: theme.spacing(2),
            '& .MuiTableContainer-root': {
                flexGrow: 1,
                '& table': {
                    height: '100%',
                },
            },
        },
        filterPanel: {
            display: 'flex',
            justifyContent: 'flex-end',
            padding: `${theme.spacing(2)} 0`,
        },
        processButton: {
            width: 'fit-content',
            marginLeft: 'auto',
            marginTop: theme.spacing(2),
            backgroundColor: theme.palette.secondary.dark,
        },
        settingButton: {
            width: 50,
            height: 50,
            marginRight: `${theme.spacing(4)}!important`,
            fontSize: '1.8rem',
            borderRadius: 0,
            transition: '0.2s',
            backgroundColor: isLightMode ? theme.palette.secondary.dark : 'rgba(0, 0, 0, 0)',
            border: isLightMode ? 'none' : `1px solid ${theme.palette.secondary.contrastText}`,
            color: isLightMode
                ? theme.palette.secondary.contrastText
                : theme.palette.primary.contrastText,
            '&:hover': {
                backgroundColor: theme.palette.secondary.dark,
                opacity: 0.6,
            },
            '& .MuiSvgIcon-root': {
                width: 35,
                height: 35,
                color: theme.palette.secondary.contrastText,
            },
            [theme.breakpoints.down('xl')]: {
                height: 40,
                width: 40,
                '& .MuiSvgIcon-root': {
                    width: 30,
                    height: 30,
                },
            },
        },
        resetSettingsButton: {
            width: 'fit-content',
            [theme.breakpoints.down('xl')]: {
                height: 40,
            },
        },
        primaryButton: {
            backgroundColor: isLightMode ? theme.palette.secondary.main : 'rgba(0, 0, 0, 0)',
            border: isLightMode ? 'none' : `1px solid ${theme.palette.secondary.contrastText}`,
            color: isLightMode
                ? theme.palette.secondary.contrastText
                : theme.palette.primary.contrastText,
            '&:hover': {
                backgroundColor: theme.palette.secondary.main,
                opacity: 0.6,
            },
            '& .MuiSvgIcon-root': {
                color: theme.palette.secondary.contrastText,
            },
        },
    };
});
