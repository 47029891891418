import React, { useCallback, useMemo, useState } from 'react';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { useLogin } from 'src/security/login-context';
import { NestedMenu } from 'src/components/base';
import { useHttpClient } from 'src/lib/http-client/use-http-client';
import { useGlobalStore } from 'src/shared/contexts';
import { GlobalStoreActions } from 'src/shared/constants';

import { generateThemeHeaderProfileMenuConfig } from './header-profile-menu-config';

export const HeaderProfileMenu = (): JSX.Element => {
    const [isImportLoading, setImportLoading] = useState<boolean>(false);
    const { t } = useTranslation(['common', 'serverErrors']);
    const navigate = useNavigate();
    const { logout, user } = useLogin();
    const httpClient = useHttpClient();
    const { dispatch } = useGlobalStore();
    const { enqueueSnackbar } = useSnackbar();

    const handleGoTo = useCallback((path: string) => navigate(path), [navigate]);

    const changeLanguage = (lang: string): void => {
        i18n.changeLanguage(lang);
    };

    const handleLogout = useCallback((): void => {
        logout();
    }, []);

    const manualImport = useCallback(() => {
        (async (): Promise<void> => {
            try {
                setImportLoading(true);
                await httpClient.post('importInsuranceConfirmations');
                if (dispatch) {
                    dispatch({ type: GlobalStoreActions.updateConfirmationInsurance });
                }
            } catch {
                enqueueSnackbar(t('unknownError', { ns: 'serverErrors' }), {
                    variant: 'error',
                });
            } finally {
                setImportLoading(false);
            }
        })();
    }, []);

    const profileMenuItems = useMemo(() => {
        return generateThemeHeaderProfileMenuConfig(t, handleLogout, changeLanguage, manualImport);
    }, [handleGoTo, handleLogout, t, manualImport]);

    return (
        <>
            <NestedMenu
                title={`${user?.firstName || ''} ${user?.lastName || ''}`}
                menuItems={profileMenuItems}
                isDisabled={isImportLoading}
            />
        </>
    );
};
