import { ReactElement } from 'react';
import { TFunction } from 'i18next';
import { Column, Row } from 'react-table';
import {
    CellWithTooltip,
    BooleanCell,
    RawXmlLinkCell,
} from 'src/components/base/custom-table/custom-table-elements';
import { withDraggable } from 'src/shared/hocs';
import { RegistrationResult } from 'src/shared/types/registration-dto';
import { useTranslation } from 'react-i18next';

export enum NewRegistrationTableKeys {
    id = 'id',
    insuranceConfirmationNumber = 'insuranceConfirmationNumber',
    contractNumber = 'contractNumber',
    licenseNumber = 'licenseNumber',
    customer = 'customer',
    result = 'result',
    resultDescription = 'resultDescription',
    customerConnection = 'customerConnection',
    risk = 'risk',
    date = 'inProgressAt',
    startOfContract = 'insuranceStartingDate',
    postcode = 'postalCode',
    place = 'placeOfResidence',
    street = 'street',
    houseNumber = 'houseNumber',
    owner = 'personName',
}

interface GenerateNewRegistrationTableConfigParams {
    t: TFunction;
    startPosition: number | undefined;
    hoverPosition: number | undefined;
    columnPositions: { [key: string]: number };
    isDndLocked: boolean;
}

interface XmlSourceLinkCellProps {
    row: Row<object>;
}

const XmlSourceLinkCell = (props: XmlSourceLinkCellProps): ReactElement => {
    return (
        <RawXmlLinkCell
            {...props}
            insuranceId={props.row.id}
            name={props.row.values['insuranceConfirmationNumber']}
        />
    );
};

interface ResultCellProps {
    value: RegistrationResult;
}

const ResultCell = (props: ResultCellProps): ReactElement => {
    return <BooleanCell {...props} value={props.value == 0} />;
};

interface ResultDescriptionCellProps {
    value: RegistrationResult;
    column: Column;
}

const ResultDescriptionCell = (props: ResultDescriptionCellProps): ReactElement => {
    const { t } = useTranslation('new-registration');

    return <CellWithTooltip {...props} value={t(RegistrationResult[props.value])} />;
};

const DraggableCell = withDraggable<{ value: string; column: Column }>((props) => (
    <CellWithTooltip {...props} />
));

export const generateNewRegistrationTableConfig = ({
    t,
    startPosition,
    hoverPosition,
    columnPositions,
    isDndLocked,
}: GenerateNewRegistrationTableConfigParams): Array<Column<object>> => {
    return [
        {
            id: NewRegistrationTableKeys.insuranceConfirmationNumber,
            Header: (props) => (
                <DraggableCell
                    {...props}
                    value={t('new-registration:evbNr')}
                    draggableId={NewRegistrationTableKeys.insuranceConfirmationNumber}
                    isDragAndDropLocked={isDndLocked}
                    hoverPosition={hoverPosition}
                    startPosition={startPosition}
                    draggableIndex={
                        columnPositions[NewRegistrationTableKeys.insuranceConfirmationNumber]
                    }
                />
            ),
            accessor: NewRegistrationTableKeys.insuranceConfirmationNumber,
            Cell: XmlSourceLinkCell,
            width: 110,
            disableSortBy: !isDndLocked,
        },
        {
            id: NewRegistrationTableKeys.licenseNumber,
            Header: (props) => (
                <DraggableCell
                    {...props}
                    value={t('new-registration:licenseNumber')}
                    draggableId={NewRegistrationTableKeys.licenseNumber}
                    isDragAndDropLocked={isDndLocked}
                    hoverPosition={hoverPosition}
                    startPosition={startPosition}
                    draggableIndex={columnPositions[NewRegistrationTableKeys.licenseNumber]}
                />
            ),
            accessor: NewRegistrationTableKeys.licenseNumber,
            Cell: CellWithTooltip,
            disableSortBy: !isDndLocked,
            width: 150,
        },
        {
            id: NewRegistrationTableKeys.customerConnection,
            Header: (props) => (
                <DraggableCell
                    {...props}
                    value={t('new-registration:customerConnection')}
                    draggableId={NewRegistrationTableKeys.customerConnection}
                    isDragAndDropLocked={isDndLocked}
                    hoverPosition={hoverPosition}
                    startPosition={startPosition}
                    draggableIndex={columnPositions[NewRegistrationTableKeys.customerConnection]}
                />
            ),
            accessor: NewRegistrationTableKeys.customerConnection,
            Cell: CellWithTooltip,
            width: 170,
            disableSortBy: !isDndLocked,
        },
        {
            id: NewRegistrationTableKeys.customer,
            Header: (props) => (
                <DraggableCell
                    {...props}
                    value={t('new-registration:customer')}
                    draggableId={NewRegistrationTableKeys.customer}
                    isDragAndDropLocked={isDndLocked}
                    hoverPosition={hoverPosition}
                    startPosition={startPosition}
                    draggableIndex={columnPositions[NewRegistrationTableKeys.customer]}
                />
            ),
            accessor: NewRegistrationTableKeys.customer,
            Cell: CellWithTooltip,
            width: 100,
            disableSortBy: !isDndLocked,
        },
        {
            id: NewRegistrationTableKeys.contractNumber,
            Header: (props) => (
                <DraggableCell
                    {...props}
                    value={t('new-registration:contractNumber')}
                    draggableId={NewRegistrationTableKeys.contractNumber}
                    isDragAndDropLocked={isDndLocked}
                    hoverPosition={hoverPosition}
                    startPosition={startPosition}
                    draggableIndex={columnPositions[NewRegistrationTableKeys.contractNumber]}
                />
            ),
            accessor: NewRegistrationTableKeys.contractNumber,
            Cell: CellWithTooltip,
            width: 100,
            disableSortBy: !isDndLocked,
        },
        {
            id: NewRegistrationTableKeys.risk,
            Header: (props) => (
                <DraggableCell
                    {...props}
                    value={t('new-registration:risk')}
                    draggableId={NewRegistrationTableKeys.risk}
                    isDragAndDropLocked={isDndLocked}
                    hoverPosition={hoverPosition}
                    startPosition={startPosition}
                    draggableIndex={columnPositions[NewRegistrationTableKeys.risk]}
                />
            ),
            accessor: NewRegistrationTableKeys.risk,
            Cell: CellWithTooltip,
            width: 70,
            disableSortBy: !isDndLocked,
        },
        {
            id: NewRegistrationTableKeys.date,
            Header: (props) => (
                <DraggableCell
                    {...props}
                    value={t('new-registration:date')}
                    draggableId={NewRegistrationTableKeys.date}
                    isDragAndDropLocked={isDndLocked}
                    hoverPosition={hoverPosition}
                    startPosition={startPosition}
                    draggableIndex={columnPositions[NewRegistrationTableKeys.date]}
                />
            ),
            accessor: NewRegistrationTableKeys.date,
            Cell: (props) => (
                <CellWithTooltip
                    {...props}
                    valueFormatter={(date) => {
                        return t('formatted-values:formattedDate', {
                            date,
                        })?.replaceAll('&#x2F;', '/');
                    }}
                />
            ),
            width: 100,
            disableSortBy: !isDndLocked,
        },
        {
            id: NewRegistrationTableKeys.startOfContract,
            Header: (props) => (
                <DraggableCell
                    {...props}
                    value={t('new-registration:startOfContract')}
                    draggableId={NewRegistrationTableKeys.startOfContract}
                    isDragAndDropLocked={isDndLocked}
                    hoverPosition={hoverPosition}
                    startPosition={startPosition}
                    draggableIndex={columnPositions[NewRegistrationTableKeys.startOfContract]}
                />
            ),
            accessor: NewRegistrationTableKeys.startOfContract,
            Cell: (props) => (
                <CellWithTooltip
                    {...props}
                    valueFormatter={(date) => {
                        return t('formatted-values:formattedDate', {
                            date,
                        })?.replaceAll('&#x2F;', '/');
                    }}
                />
            ),
            width: 100,
            disableSortBy: !isDndLocked,
        },
        {
            id: NewRegistrationTableKeys.postcode,
            Header: (props) => (
                <DraggableCell
                    {...props}
                    value={t('new-registration:postcode')}
                    draggableId={NewRegistrationTableKeys.postcode}
                    isDragAndDropLocked={isDndLocked}
                    hoverPosition={hoverPosition}
                    startPosition={startPosition}
                    draggableIndex={columnPositions[NewRegistrationTableKeys.postcode]}
                />
            ),
            accessor: NewRegistrationTableKeys.postcode,
            Cell: CellWithTooltip,
            width: 60,
            disableSortBy: !isDndLocked,
        },
        {
            id: NewRegistrationTableKeys.place,
            Header: (props) => (
                <DraggableCell
                    {...props}
                    value={t('new-registration:place')}
                    draggableId={NewRegistrationTableKeys.place}
                    isDragAndDropLocked={isDndLocked}
                    hoverPosition={hoverPosition}
                    startPosition={startPosition}
                    draggableIndex={columnPositions[NewRegistrationTableKeys.place]}
                />
            ),
            accessor: NewRegistrationTableKeys.place,
            Cell: CellWithTooltip,
            width: 95,
            disableSortBy: !isDndLocked,
        },
        {
            id: NewRegistrationTableKeys.street,
            Header: (props) => (
                <DraggableCell
                    {...props}
                    value={t('new-registration:street')}
                    draggableId={NewRegistrationTableKeys.street}
                    isDragAndDropLocked={isDndLocked}
                    hoverPosition={hoverPosition}
                    startPosition={startPosition}
                    draggableIndex={columnPositions[NewRegistrationTableKeys.street]}
                />
            ),
            accessor: NewRegistrationTableKeys.street,
            Cell: CellWithTooltip,
            width: 80,
            disableSortBy: true,
        },
        {
            id: NewRegistrationTableKeys.houseNumber,
            Header: (props) => (
                <DraggableCell
                    {...props}
                    value={t('new-registration:houseNumber')}
                    draggableId={NewRegistrationTableKeys.houseNumber}
                    isDragAndDropLocked={isDndLocked}
                    hoverPosition={hoverPosition}
                    startPosition={startPosition}
                    draggableIndex={columnPositions[NewRegistrationTableKeys.houseNumber]}
                />
            ),
            accessor: NewRegistrationTableKeys.houseNumber,
            Cell: CellWithTooltip,
            width: 50,
            disableSortBy: true,
        },
        {
            id: NewRegistrationTableKeys.owner,
            Header: (props) => (
                <DraggableCell
                    {...props}
                    value={t('new-registration:owner')}
                    draggableId={NewRegistrationTableKeys.owner}
                    isDragAndDropLocked={isDndLocked}
                    hoverPosition={hoverPosition}
                    startPosition={startPosition}
                    draggableIndex={columnPositions[NewRegistrationTableKeys.owner]}
                />
            ),
            accessor: NewRegistrationTableKeys.owner,
            Cell: CellWithTooltip,
            width: 140,
            disableSortBy: !isDndLocked,
        },
        {
            id: NewRegistrationTableKeys.result,
            Header: (props) => (
                <DraggableCell
                    {...props}
                    value={t('new-registration:result')}
                    draggableId={NewRegistrationTableKeys.result}
                    isDragAndDropLocked={isDndLocked}
                    hoverPosition={hoverPosition}
                    startPosition={startPosition}
                    draggableIndex={columnPositions[NewRegistrationTableKeys.result]}
                />
            ),
            accessor: NewRegistrationTableKeys.result,
            Cell: ResultCell,
            width: 100,
            disableSortBy: !isDndLocked,
        },
        {
            id: NewRegistrationTableKeys.resultDescription,
            Header: (props) => (
                <DraggableCell
                    {...props}
                    value={t('new-registration:resultDescription')}
                    draggableId={NewRegistrationTableKeys.resultDescription}
                    isDragAndDropLocked={isDndLocked}
                    hoverPosition={hoverPosition}
                    startPosition={startPosition}
                    draggableIndex={columnPositions[NewRegistrationTableKeys.resultDescription]}
                />
            ),
            accessor: NewRegistrationTableKeys.result,
            Cell: ResultDescriptionCell,
            width: 100,
            disableSortBy: !isDndLocked,
        },
    ];
};
