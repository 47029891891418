import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { THEME_MODES } from 'src/theming/theme-modes';

export const useToggleButtonsGroupStyle = makeStyles((theme: Theme) => {
    const isLightMode = theme.palette.mode === THEME_MODES.LIGHT;

    return {
        root: {
            backgroundColor: isLightMode ? theme.palette.secondary.light : 'rgba(0, 0, 0, 0)',
        },
    };
});

export const useToggleButtonStyle = makeStyles((theme: Theme) => {
    const isLightMode = theme.palette.mode === THEME_MODES.LIGHT;

    return {
        root: {
            height: 50,
            padding: `0 ${theme.spacing(3)}px`,
            borderRadius: 0,
            borderColor: !isLightMode
                ? theme.palette.secondary.contrastText
                : theme.palette.secondary.dark,
            color: !isLightMode
                ? theme.palette.secondary.contrastText
                : theme.palette.secondary.dark,
            fontSize: '1.8rem',
            textTransform: 'none',
            [theme.breakpoints.down('xl')]: {
                height: 40,
            },
        },
        selected: {
            backgroundColor: isLightMode
                ? `${theme.palette.secondary.dark}!important`
                : `${theme.palette.secondary.contrastText}!important`,
            color: isLightMode
                ? `${theme.palette.secondary.contrastText}!important`
                : `${theme.palette.secondary.dark}!important`,
        },
    };
});
