import { AppRoutes } from 'src/routing/app-routes';

import {
    LoginPage,
    ForgotPasswordPage,
    NewRegistrationPage,
    SigningOffPage,
    ChangePage,
    CreatePasswordPage,
} from 'src/pages';
import { MainLayout } from 'src/components/layouts';
import { RouteItem } from './route-item';

export const routingConfig: RouteItem[] = [
    {
        component: LoginPage,
        path: AppRoutes.LOGIN,
    },
    {
        component: ForgotPasswordPage,
        path: AppRoutes.FORGOT_PASSWORD,
    },
    {
        component: CreatePasswordPage,
        path: AppRoutes.CREATE_PASSWORD,
    },
    {
        private: true,
        component: MainLayout,
        path: AppRoutes.ROOT,
        children: [
            {
                private: true,
                component: ChangePage,
                path: AppRoutes.CHANGE,
            },
            {
                private: true,
                component: NewRegistrationPage,
                path: AppRoutes.NEW_REGISTRATION,
            },
            {
                private: true,
                component: SigningOffPage,
                path: AppRoutes.SIGNING_OFF,
            },
        ],
    },
];
