import React, { ReactElement, Suspense } from 'react';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import { LinearProgress } from '@mui/material';

import { useLogin } from 'src/security/login-context';
import { routingConfig } from './routing-config';
import { ProtectedRoute } from './protected-route';

export const RoutedContent = (): ReactElement => {
    const { user } = useLogin();
    const isAuthorized = user != null;

    return (
        <Suspense fallback={<LinearProgress />}>
            <BrowserRouter>
                <Routes>
                    {routingConfig.map((route) => {
                        const { path, private: isPrivate, component: Component, children } = route;
                        return (
                            <Route
                                key={path}
                                element={
                                    <ProtectedRoute
                                        isPrivate={Boolean(isPrivate)}
                                        authorized={isAuthorized}
                                    />
                                }
                                {...route}
                            >
                                <Route path={path} element={<Component />}>
                                    {Array.isArray(children) &&
                                        children.map((childRoute) => {
                                            const ChildComponent = childRoute.component;
                                            return (
                                                <Route
                                                    key={childRoute.path}
                                                    element={
                                                        <ProtectedRoute
                                                            isPrivate={Boolean(childRoute.private)}
                                                            authorized={isAuthorized}
                                                        />
                                                    }
                                                    {...childRoute}
                                                >
                                                    <Route
                                                        path={path}
                                                        element={<ChildComponent />}
                                                    />
                                                </Route>
                                            );
                                        })}
                                </Route>
                            </Route>
                        );
                    })}
                </Routes>
            </BrowserRouter>
        </Suspense>
    );
};
