import { useState, useEffect } from 'react';
import { DragStart, DropResult, DragUpdate, ResponderProvided } from 'react-beautiful-dnd';

import { TableSettingsDto } from 'src/shared/types';

interface UseColumnDndReturn {
    startPosition: number | undefined;
    hoverPosition: number | undefined;
    onDragStart: (initial: DragStart, provided: ResponderProvided) => void;
    onDragUpdate: (initial: DragUpdate, provided: ResponderProvided) => void;
    onDragEnd: (result: DropResult, provided: ResponderProvided) => void;
    columnPositions: { [key: string]: number };
}

interface UseColumnDndParams {
    settings: TableSettingsDto | null;
    dragEndCallback: (columnPositions: { [key: string]: number }) => void;
    defaultState?: { [key: string]: number };
}

export const useColumnDnd = ({
    defaultState,
    settings,
    dragEndCallback,
}: UseColumnDndParams): UseColumnDndReturn => {
    const [hoverPosition, setHoverPosition] = useState<number>();
    const [startPosition, setStartPosition] = useState<number>();

    const [columnPositions, setColumnPositions] = useState<{ [key: string]: number }>(
        defaultState || {}
    );

    const onDragStart = (initial: DragStart): void => {
        if (initial.source) {
            setStartPosition(initial?.source?.index);
        }
    };

    const onDragEnd = (result: DropResult): void => {
        if (result.source && result.destination) {
            setHoverPosition(undefined);
            setStartPosition(undefined);
        }
        dragEndCallback(columnPositions);
    };

    const onDragUpdate = (initial: DragUpdate): void => {
        if (initial.destination) {
            const newColumnPositions = {
                ...columnPositions,
                [initial.draggableId]: initial.destination.index,
            };
            const destinationIndex = initial.destination.index;
            const destinationKey = Object.entries(columnPositions).find(
                (item) => item[1] === destinationIndex
            )?.[0];

            if (destinationKey && typeof destinationIndex === 'number') {
                const positionToCheck =
                    typeof hoverPosition === 'number' ? hoverPosition : initial.source.index;
                if (positionToCheck > destinationIndex) {
                    newColumnPositions[destinationKey] = destinationIndex + 1;
                }
                if (positionToCheck < destinationIndex) {
                    newColumnPositions[destinationKey] = destinationIndex - 1;
                }
            }

            setColumnPositions(newColumnPositions);
            setHoverPosition(initial.destination.index);
        }
    };

    useEffect(() => {
        if (settings && Array.isArray(settings.columns)) {
            setColumnPositions(
                Object.fromEntries(settings.columns.map((item) => [item.name, item.position]))
            );
        } else {
            setColumnPositions(defaultState || {});
        }
    }, [settings]);

    return {
        hoverPosition,
        startPosition,
        onDragStart,
        onDragEnd,
        onDragUpdate,
        columnPositions,
    };
};
