import React, { cloneElement, MutableRefObject, ReactElement, useRef } from 'react';
import clsx from 'clsx';

import { Tooltip } from '@mui/material';

import { useOverflowActive } from 'src/shared/hooks';

import { useStyles } from './overflow-tooltip-styles';

type OverflowTooltipProps = {
    label: string;
    children: ReactElement<{
        ref: MutableRefObject<HTMLElement | undefined>;
        className: string;
    }>;
    dataForTooltip?: string | ReactElement;
};

export const OverflowTooltip = ({
    label,
    children,
    dataForTooltip,
}: OverflowTooltipProps): JSX.Element => {
    const {
        props: { className: childClassName },
    } = children;

    const classes = useStyles();
    const ref = useRef();

    const overflowActive = useOverflowActive({ ref, label });

    const childElementWithRef = cloneElement(children, {
        ref,
        className: clsx(classes.overflowChild, childClassName && childClassName),
    });

    const renderWithTooltip = (): JSX.Element => (
        <Tooltip
            title={dataForTooltip || label}
            placement='top'
            classes={{ tooltip: classes.tooltip }}
        >
            {childElementWithRef}
        </Tooltip>
    );

    return overflowActive || dataForTooltip ? renderWithTooltip() : childElementWithRef;
};
