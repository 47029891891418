import React, { useMemo } from 'react';
import useDarkMode from 'use-dark-mode';
import { I18nextProvider } from 'react-i18next';
import { SnackbarProvider } from 'notistack';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

import { usePathInterceptor, useAppVersionInterceptor } from './shared/hooks';

import { themes } from './theming/theme';
import { RoutedContent } from './routing/routed-content';
import i18n from './i18-next.config';

function App(): JSX.Element {
    const darkMode = useDarkMode(true);

    const currentTheme = useMemo(
        () => (darkMode.value ? themes.darkTheme : themes.lightTheme),
        [darkMode]
    );

    usePathInterceptor();
    useAppVersionInterceptor();

    return (
        <I18nextProvider i18n={i18n}>
            <ThemeProvider theme={currentTheme}>
                <StyledEngineProvider injectFirst>
                    <CssBaseline />
                    <SnackbarProvider
                        style={{ maxWidth: 500 }}
                        anchorOrigin={{
                            horizontal: 'right',
                            vertical: 'bottom',
                        }}
                    >
                        <RoutedContent />
                    </SnackbarProvider>
                </StyledEngineProvider>
            </ThemeProvider>
        </I18nextProvider>
    );
}

export default App;
