import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

import { THEME_MODES } from 'src/theming/theme-modes';
import { LIGHT_THEME_COLORS } from 'src/theming/theme-colors';

export const useStyle = makeStyles((theme: Theme) => {
    const isDarkMode = theme.palette.mode === THEME_MODES.DARK;

    return {
        formContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        formTitle: {
            textAlign: 'center',
            fontSize: '2.7rem',
            color: theme.palette.primary.contrastText,
            [theme.breakpoints.down('xl')]: {
                fontSize: '2.5rem',
            },
        },
        formMessage: {
            fontSize: '2.4rem',
            textAlign: 'center',
            padding: `${theme.spacing(7)} 0`,
            color: theme.palette.primary.contrastText,
            [theme.breakpoints.down('xl')]: {
                padding: `${theme.spacing(4)} 0`,
                fontSize: '2.2rem',
            },
        },
        submitButton: {
            width: 210,
            height: 40,
            marginTop: theme.spacing(4),
            boxSizing: 'border-box',
            backgroundColor: isDarkMode ? `rgba(0, 0, 0, 0)` : theme.palette.secondary.dark,
            color: theme.palette.secondary.contrastText,
            borderRadius: 0,
            border: isDarkMode ? `1px solid ${theme.palette.secondary.contrastText}` : 'none',
            '&:hover': {
                backgroundColor: isDarkMode
                    ? theme.palette.common.white
                    : theme.palette.primary.main,
                border: !isDarkMode ? `1px solid ${theme.palette.secondary.main}` : 'none',
                color: theme.palette.secondary.main,
            },
        },
        forgotPasswordLink: {
            fontSize: '1.6rem',
            marginTop: theme.spacing(4),
            color: isDarkMode ? theme.palette.common.white : theme.palette.info.main,
        },
        goToLoginLink: {
            fontSize: '1.6rem',
            marginTop: theme.spacing(4),
            '& a': {
                color: isDarkMode ? theme.palette.common.white : theme.palette.info.main,
            },
        },
        strengthBar: {
            width: '100%',
            fontSize: '1.6rem',
            '& li': {
                color: theme.palette.primary.contrastText,
                opacity: 0.6,
            },
        },
    };
});

export const useFormControlStyles = makeStyles((theme: Theme) => {
    return {
        root: {
            margin: `${theme.spacing(2)} 0`,
            '& label': {
                fontSize: '2.4rem',
            },
        },
    };
});

export const useFormControlLabelStyles = makeStyles((theme: Theme) => {
    return {
        root: {
            alignItems: 'center',
            marginLeft: 0,
            marginRight: 0,
        },
        label: {
            fontSize: '1.8rem',
            marginBottom: theme.spacing(3),
            [theme.breakpoints.down('xl')]: {
                fontSize: '1.6rem',
                marginBottom: theme.spacing(1),
            },
        },
    };
});

export const useInputStyles = makeStyles((theme: Theme) => {
    return {
        root: {
            width: '100%',
            height: 40,
            backgroundColor: theme.palette.common.white,
            '& fieldset': {
                borderRadius: 0,
            },
        },
        focused: {
            '& fieldset': {
                borderColor: `${theme.palette.info.main}!important`,
            },
        },
        input: {
            height: 40,
            boxSizing: 'border-box',
            color: theme.palette.secondary.main,
            fontSize: '1.8rem',
            '&::placeholder': {
                color: theme.palette.grey['700'],
                fontSize: '1.8rem',
            },
            '&:-internal-autofill-selected': {
                boxShadow: `inset 0 0 0 1px rgba(255, 255, 255, 0), inset 0 0 0 100px ${
                    theme.palette.mode === THEME_MODES.LIGHT
                        ? theme.palette.primary.main
                        : LIGHT_THEME_COLORS.SECONDARY_MAIN_COLOR
                }`,
            },
        },
    };
});
