import { useEffect, useState, useMemo, useCallback, Dispatch, SetStateAction } from 'react';
import { CheckboxItem } from 'src/components/base';
import { TableColumnSettings, TableSettingsDto } from 'src/shared/types';
import { SigningOffTableKeys } from '../signing-off-table-config';

const defaultState = [
    {
        title: 'signing-off:evbNr',
        value: SigningOffTableKeys.insuranceConfirmationNumber,
        checked: true,
    },
    {
        title: 'signing-off:contractNumber',
        value: SigningOffTableKeys.contractNumber,
        checked: true,
    },
    {
        title: 'signing-off:licenseNumber',
        value: SigningOffTableKeys.licenseNumber,
        checked: true,
    },
    {
        title: 'signing-off:contractStatus',
        value: SigningOffTableKeys.contractStatus,
        checked: true,
    },
    {
        title: 'signing-off:customer',
        value: SigningOffTableKeys.customer,
        checked: true,
    },
    {
        title: 'signing-off:result',
        value: SigningOffTableKeys.result,
        checked: true,
    },
    {
        title: 'signing-off:date',
        value: SigningOffTableKeys.date,
        checked: true,
    },
    {
        title: 'signing-off:customerConnection',
        value: SigningOffTableKeys.customerConnection,
        checked: true,
    },
    {
        title: 'signing-off:startOfContract',
        value: SigningOffTableKeys.startOfContract,
        checked: true,
    },
    {
        title: 'signing-off:postcode',
        value: SigningOffTableKeys.postcode,
        checked: true,
    },
    {
        title: 'signing-off:place',
        value: SigningOffTableKeys.place,
        checked: true,
    },
    {
        title: 'signing-off:street',
        value: SigningOffTableKeys.street,
        checked: true,
    },
    {
        title: 'signing-off:houseNumber',
        value: SigningOffTableKeys.houseNumber,
        checked: true,
    },
    {
        title: 'signing-off:owner',
        value: SigningOffTableKeys.owner,
        checked: true,
    },
];

interface UseColumnVisibilityStateReturn {
    visibilitySettingsList: CheckboxItem<TableColumnSettings>[];
    hiddenColumns: string[];
    handleColumnSettingsList: (settings: CheckboxItem[]) => void;
}

interface UseColumnVisibilityStateParams {
    settings: TableSettingsDto | null;
    setSettingsDropdownChanged: Dispatch<SetStateAction<boolean>>;
}

export const useColumnVisibilityState = ({
    settings,
    setSettingsDropdownChanged,
}: UseColumnVisibilityStateParams): UseColumnVisibilityStateReturn => {
    const [visibilitySettingsList, setVisibilitySettingsList] =
        useState<CheckboxItem<TableColumnSettings>[]>(defaultState);

    const handleColumnSettingsList = useCallback((newSettingsList): void => {
        setSettingsDropdownChanged(true);
        setVisibilitySettingsList(newSettingsList);
    }, []);

    useEffect(() => {
        if (settings && Array.isArray(settings.columns)) {
            const newList = visibilitySettingsList.map((settingsItem) => {
                return {
                    ...settingsItem,
                    checked:
                        settings?.columns?.find((item) => item.name === settingsItem.value)
                            ?.isShown || false,
                    data: settings?.columns?.find((item) => item.name === settingsItem.value),
                };
            });
            setVisibilitySettingsList(newList);
            setSettingsDropdownChanged(false);
        }
    }, [settings]);

    const hiddenColumns = useMemo(() => {
        return visibilitySettingsList
            .filter((settingItem) => settingItem.checked === false)
            .map((settingItem) => settingItem.value);
    }, [visibilitySettingsList]);

    return { visibilitySettingsList, hiddenColumns, handleColumnSettingsList };
};
