import { TFunction } from 'i18next';

import { MenuItem } from 'src/components/base';
import { Languages } from 'src/shared/constants';

export const generateThemeHeaderProfileMenuConfig = (
    t: TFunction,
    handleLogout: () => void,
    changeLanguage: (path: string) => void,
    manualImportAction: () => void
): MenuItem[] => {
    const profileMenu: MenuItem[] = [
        {
            title: t('profileMenu.manualImportEVBS'),
            action: manualImportAction,
        },
        {
            title: t('profileMenu.language'),
            nestedMenu: [
                {
                    title: t('profileMenu.german'),
                    action: () => changeLanguage(Languages.DE),
                },
                {
                    title: t('profileMenu.english'),
                    action: () => changeLanguage(Languages.EN),
                },
            ],
        },
        { title: t('profileMenu.logout'), action: handleLogout },
    ];
    return profileMenu;
};
