import { GlobalStoreActions } from 'src/shared/constants';
import { GlobalStoreActionsType, ConfirmationInsuranceSearchAction } from 'src/shared/types';
import { GlobalStoreType } from './global-store';

export function globalStoreReducer(state: GlobalStoreType, action: GlobalStoreActionsType) {
    switch (action.type) {
        case GlobalStoreActions.updateConfirmationInsurance:
            return { ...state, needToRefetchGlobalConfirmations: true };
        case GlobalStoreActions.resetConfirmationInsurance:
            return { ...state, needToRefetchGlobalConfirmations: false };
        case GlobalStoreActions.changeConfirmationInsuranceSearchValue:
            return {
                ...state,
                insuranceConfirmationSearch: {
                    value: (action as ConfirmationInsuranceSearchAction).payload.value,
                },
            };
        default:
            throw new Error();
    }
}
