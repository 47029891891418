import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { useTheme } from '@mui/material';

import { AppRoutes } from 'src/routing/app-routes';
import { THEME_MODES } from 'src/theming/theme-modes';

import { useHeaderStyle } from './header-styles';

export const HeaderLogo = (): JSX.Element => {
    const classes = useHeaderStyle();
    const navigate = useNavigate();
    const theme = useTheme();

    const goHome = useCallback((): void => {
        navigate(AppRoutes.NEW_REGISTRATION);
    }, [navigate]);

    return (
        <div
            style={{
                padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
            }}
        >
            <img
                alt='logo'
                width={208}
                height={41}
                className={classes.logo}
                src={`/static/images/${
                    theme.palette.mode === THEME_MODES.DARK ? 'dark-' : 'light-'
                }theme-logo.png`}
                onClick={goHome}
            />
        </div>
    );
};
