import React, { BaseSyntheticEvent, SyntheticEvent } from 'react';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import clsx from 'clsx';

import {
    Button,
    Typography,
    Popover,
    MenuItem as MaterialMenuItem,
    IconButton,
    useTheme,
} from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { useNestedMenuStyles } from './nested-menu-styles';
import { NestedMenuItem } from './nested-menu-item';

export interface MenuItem {
    title?: string;
    action?: () => void;
    nestedMenu?: MenuItem[];
    icon?: JSX.Element;
    isAdditionalActionActive?: boolean;
    isActive?: boolean;
    additionalAction?: () => void;
    isDisabled?: boolean;
}

export interface NestedMenuProps {
    menuItems: MenuItem[];
    title?: string;
    buttonIcon?: JSX.Element;
    buttonClass?: string;
    icon?: JSX.Element;
    isAdditionalActionActive?: boolean;
    additionalAction?: () => void;
    isDisabled?: boolean;
    startIcon?: JSX.Element;
}

export const NestedMenu = (props: NestedMenuProps): JSX.Element => {
    const classes = useNestedMenuStyles();
    const theme = useTheme();

    const {
        menuItems,
        title,
        buttonIcon,
        buttonClass,
        isDisabled,
        isAdditionalActionActive,
        additionalAction,
        startIcon,
    } = props;

    const handleStopPropagation = (e: SyntheticEvent): void => {
        e.stopPropagation();
    };

    const handleAdditionalAction = (e: BaseSyntheticEvent): void => {
        e.stopPropagation();
        if (typeof additionalAction === 'function') {
            additionalAction();
        }
    };

    return (
        <div
            role='button'
            tabIndex={0}
            onClick={handleStopPropagation}
            onKeyPress={handleStopPropagation}
        >
            <PopupState variant='popover'>
                {(popupState) => {
                    return (
                        <div>
                            {buttonIcon ? (
                                <IconButton
                                    size='small'
                                    className={clsx(classes.iconButton, buttonClass)}
                                    {...bindTrigger(popupState)}
                                >
                                    {buttonIcon}
                                </IconButton>
                            ) : (
                                <Button
                                    disableRipple
                                    className={clsx(classes.button, buttonClass)}
                                    startIcon={startIcon}
                                    endIcon={
                                        popupState.isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />
                                    }
                                    color='primary'
                                    {...bindTrigger(popupState)}
                                >
                                    <Typography
                                        className={classes.buttonText}
                                        noWrap
                                        variant='body1'
                                    >
                                        {title}
                                    </Typography>
                                </Button>
                            )}
                            <Popover
                                {...bindPopover(popupState)}
                                className={classes.itemsContainer}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                                elevation={2}
                            >
                                {menuItems.map((item: MenuItem) =>
                                    !item.nestedMenu ? (
                                        <MaterialMenuItem
                                            disabled={isDisabled}
                                            key={item.title}
                                            className={classes.menuItem}
                                            onClick={() => {
                                                if (typeof item.action === 'function') {
                                                    item.action();
                                                }
                                                popupState.close();
                                            }}
                                            style={{
                                                backgroundColor: item.isActive
                                                    ? theme.palette.info.light
                                                    : 'default',
                                            }}
                                        >
                                            <Typography
                                                variant='inherit'
                                                className={classes.menuItemText}
                                            >
                                                {item.title}
                                            </Typography>
                                            {item.icon ? (
                                                <IconButton
                                                    size='small'
                                                    onClick={handleAdditionalAction}
                                                    className={clsx(
                                                        classes.additionalActive,
                                                        isAdditionalActionActive
                                                            ? classes.additionalActionActive
                                                            : null
                                                    )}
                                                >
                                                    {item.icon}
                                                </IconButton>
                                            ) : null}
                                        </MaterialMenuItem>
                                    ) : (
                                        <NestedMenuItem
                                            isDisabled={isDisabled}
                                            key={item.title}
                                            item={item}
                                            closePopup={popupState.close}
                                        />
                                    )
                                )}
                            </Popover>
                        </div>
                    );
                }}
            </PopupState>
        </div>
    );
};
