import React, { ReactElement, useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Grid } from '@mui/material';

import { Header, Footer, Navigation } from 'src/components/base';
import { Search } from 'src/components/fields';
import { useGlobalStore } from 'src/shared/contexts';
import { GlobalStoreActions } from 'src/shared/constants';

import { useMainLayoutStyles } from './main-layout-styles';
import { generateNavigationPanelConfig } from './main-layout-navigation-config';

export const MainLayout = (): ReactElement => {
    const classes = useMainLayoutStyles();
    const { t } = useTranslation(['common']);
    const location = useLocation();

    const { store, dispatch } = useGlobalStore();

    const tabs = useMemo(() => generateNavigationPanelConfig(t), [t]);

    const activeTab = location.pathname;

    const handleChange = (_: string, value: string): void => {
        if (typeof dispatch === 'function') {
            dispatch({
                type: GlobalStoreActions.changeConfirmationInsuranceSearchValue,
                payload: { value },
            });
        }
    };

    return (
        <Grid container className={classes.container} direction='column'>
            <Grid item className={classes.header}>
                <Header />
            </Grid>
            <Grid item container className={classes.navigationPanel}>
                <Grid item sm={8}>
                    <Navigation tabs={tabs} activeTab={activeTab} />
                </Grid>
                <Grid item sm={4} className={classes.searchContainer}>
                    <Search
                        disabled={false}
                        name='search'
                        value={store.insuranceConfirmationSearch.value}
                        handleChange={handleChange}
                    />
                </Grid>
            </Grid>
            <Grid item component='main' className={classes.main}>
                <Outlet />
            </Grid>
            <Grid item className={classes.footer}>
                <Footer />
            </Grid>
        </Grid>
    );
};
