import { TFunction } from 'react-i18next';
import { IThemeTab } from 'src/components/base';
import { AppRoutes } from 'src/routing/app-routes';

export const generateNavigationPanelConfig = (t: TFunction): IThemeTab[] => {
    return [
        {
            title: `${t('common:navigationPanel.newRegistration')}`,
            value: AppRoutes.NEW_REGISTRATION,
            path: AppRoutes.NEW_REGISTRATION,
        },
        {
            title: `${t('common:navigationPanel.change')}`,
            value: AppRoutes.CHANGE,
            path: AppRoutes.CHANGE,
        },
        {
            title: `${t('common:navigationPanel.signingOff')}`,
            value: AppRoutes.SIGNING_OFF,
            path: AppRoutes.SIGNING_OFF,
        },
    ];
};
