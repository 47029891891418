import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyle = makeStyles((theme: Theme) => {
    return {
        footer: {
            display: 'flex',
            height: 60,
            justifyContent: 'center',
            alignItems: 'center',
            color: 'white',
            fontSize: 12,
            [theme.breakpoints.down('xl')]: {
                height: 30,
            },
        },
        separator: {
            margin: '0 1rem',
        },
        footerLink: {
            color: theme.palette.secondary.contrastText,
            textDecorationColor: theme.palette.secondary.contrastText,
        },
    };
});
