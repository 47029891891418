import React, { useState } from 'react';
import useDarkMode from 'use-dark-mode';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

import {
    Typography,
    FormControl,
    FormControlLabel,
    OutlinedInput,
    Button,
    Link,
} from '@mui/material';
import { CircularProgress } from 'src/components/base';
import { ResponseError } from 'src/shared/types';
import { useLogin } from 'src/security/login-context';
import { AppRoutes } from 'src/routing/app-routes';

import {
    useStyle,
    useInputStyles,
    useFormControlStyles,
    useFormControlLabelStyles,
} from './login-form-styles';
import { loginSchema } from './login-form-validation-schema';
import { getAllLoginErrors } from './get-all-login-errors';

export type LoginFormData = {
    email: string;
    password: string;
};

export const LoginForm = (): JSX.Element => {
    const darkMode = useDarkMode();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState<boolean>(false);

    const { login } = useLogin();

    const classes = useStyle({ isDarkMode: darkMode.value });
    const inputClasses = useInputStyles();
    const formControlClasses = useFormControlStyles();
    const formControlLabelClasses = useFormControlLabelStyles();

    const { t } = useTranslation(['login', 'serverErrors']);

    const {
        register,
        handleSubmit,
        formState: { isValid, errors },
    } = useForm<LoginFormData>({
        resolver: yupResolver(loginSchema),
        mode: 'onChange',
        shouldFocusError: true,
    });

    const onSubmit = async ({ email, password }: LoginFormData): Promise<void> => {
        try {
            setLoading(true);
            await login(email, password);
            navigate(AppRoutes.NEW_REGISTRATION);
        } catch (err) {
            const errors = getAllLoginErrors(err as ResponseError);
            if (errors.length > 0) {
                errors.forEach((error) => {
                    enqueueSnackbar(t(error, { ns: 'serverErrors' }), {
                        variant: 'error',
                    });
                });
            } else {
                enqueueSnackbar(t('unknownError', { ns: 'serverErrors' }), {
                    variant: 'error',
                });
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <form noValidate onSubmit={handleSubmit(onSubmit)} className={classes.formContainer}>
            <Typography variant='h3' className={classes.formTitle}>
                {t('login:loginTitle')}
            </Typography>
            <Typography variant='body1' className={classes.formMessage}>
                {t('loginMessage')}
            </Typography>
            <FormControl
                fullWidth
                error={Boolean(errors?.email)}
                classes={formControlClasses}
                variant='outlined'
            >
                <FormControlLabel
                    classes={formControlLabelClasses}
                    control={
                        <OutlinedInput
                            disabled={isLoading}
                            type='email'
                            placeholder={t('placeholders.enterEmail')}
                            classes={inputClasses}
                            {...register('email')}
                        />
                    }
                    label={t<string>('fields.email')}
                    labelPlacement='top'
                />
            </FormControl>
            <FormControl
                error={Boolean(errors?.password)}
                fullWidth
                classes={formControlClasses}
                variant='outlined'
            >
                <FormControlLabel
                    classes={formControlLabelClasses}
                    control={
                        <OutlinedInput
                            disabled={isLoading}
                            type='password'
                            placeholder={t('placeholders.enterPassword')}
                            classes={inputClasses}
                            {...register('password')}
                        />
                    }
                    label={t<string>('fields.password')}
                    labelPlacement='top'
                />
            </FormControl>
            <Button
                disableElevation
                disabled={!isValid || isLoading}
                startIcon={<CircularProgress isLoading={isLoading} />}
                className={classes.submitButton}
                variant='contained'
                type='submit'
            >
                {t('login')}
            </Button>
            <Link href='forgot-password' className={classes.forgotPasswordLink}>{`${t(
                'forgotPassword'
            )}?`}</Link>
        </form>
    );
};
