import { TFunction } from 'i18next';
import { ReactElement } from 'react';
import { Column, Row } from 'react-table';
import {
    CellWithTooltip,
    RawXmlLinkCell,
} from 'src/components/base/custom-table/custom-table-elements';
import { withDraggable } from 'src/shared/hocs';

export enum ChangeTableKeys {
    id = 'id',
    insuranceConfirmationNumber = 'insuranceConfirmationNumber',
    licenseNumber = 'licenseNumber',
    owner = 'personName',
    agencyNumber = 'agencyNumber',
    date = 'inProgressAt',
    startOfContract = 'insuranceStartingDate',
    postcode = 'postalCode',
    place = 'placeOfResidence',
    street = 'street',
    houseNumber = 'houseNumber',
}

interface GenerateChangeTableConfigParams {
    t: TFunction;
    startPosition: number | undefined;
    hoverPosition: number | undefined;
    columnPositions: { [key: string]: number };
    isDndLocked: boolean;
}

interface XmlSourceLinkCellProps {
    row: Row<object>;
}

const XmlSourceLinkCell = (props: XmlSourceLinkCellProps): ReactElement => {
    return (
        <RawXmlLinkCell
            {...props}
            insuranceId={props.row.id}
            name={props.row.values['insuranceConfirmationNumber']}
        />
    );
};

const DraggableCell = withDraggable<{ value: string; column: Column }>((props) => (
    <CellWithTooltip {...props} />
));

export const generateChangeTableConfig = ({
    t,
    startPosition,
    hoverPosition,
    columnPositions,
    isDndLocked,
}: GenerateChangeTableConfigParams): Array<Column<object>> => {
    return [
        {
            id: ChangeTableKeys.insuranceConfirmationNumber,
            Header: (props) => (
                <DraggableCell
                    {...props}
                    value={t('change:evbNr')}
                    draggableId={ChangeTableKeys.insuranceConfirmationNumber}
                    isDragAndDropLocked={isDndLocked}
                    hoverPosition={hoverPosition}
                    startPosition={startPosition}
                    draggableIndex={columnPositions[ChangeTableKeys.insuranceConfirmationNumber]}
                />
            ),
            accessor: ChangeTableKeys.insuranceConfirmationNumber,
            Cell: XmlSourceLinkCell,
            width: 110,
            disableSortBy: !isDndLocked,
        },
        {
            id: ChangeTableKeys.licenseNumber,
            Header: (props) => (
                <DraggableCell
                    {...props}
                    value={t('change:licenseNumber')}
                    draggableId={ChangeTableKeys.licenseNumber}
                    isDragAndDropLocked={isDndLocked}
                    hoverPosition={hoverPosition}
                    startPosition={startPosition}
                    draggableIndex={columnPositions[ChangeTableKeys.licenseNumber]}
                />
            ),
            accessor: ChangeTableKeys.licenseNumber,
            Cell: CellWithTooltip,
            disableSortBy: !isDndLocked,
            width: 150,
        },
        {
            id: ChangeTableKeys.owner,
            Header: (props) => (
                <DraggableCell
                    {...props}
                    value={t('change:owner')}
                    draggableId={ChangeTableKeys.owner}
                    isDragAndDropLocked={isDndLocked}
                    hoverPosition={hoverPosition}
                    startPosition={startPosition}
                    draggableIndex={columnPositions[ChangeTableKeys.owner]}
                />
            ),
            accessor: ChangeTableKeys.owner,
            Cell: CellWithTooltip,
            disableSortBy: !isDndLocked,
            width: 140,
        },
        {
            id: ChangeTableKeys.agencyNumber,
            Header: (props) => (
                <DraggableCell
                    {...props}
                    value={t('change:agencyNumber')}
                    draggableId={ChangeTableKeys.agencyNumber}
                    isDragAndDropLocked={isDndLocked}
                    hoverPosition={hoverPosition}
                    startPosition={startPosition}
                    draggableIndex={columnPositions[ChangeTableKeys.agencyNumber]}
                />
            ),
            accessor: ChangeTableKeys.agencyNumber,
            Cell: CellWithTooltip,
            disableSortBy: !isDndLocked,
            width: 100,
        },
        {
            id: ChangeTableKeys.date,
            Header: (props) => (
                <DraggableCell
                    {...props}
                    value={t('change:date')}
                    draggableId={ChangeTableKeys.date}
                    isDragAndDropLocked={isDndLocked}
                    hoverPosition={hoverPosition}
                    startPosition={startPosition}
                    draggableIndex={columnPositions[ChangeTableKeys.date]}
                />
            ),
            accessor: ChangeTableKeys.date,
            Cell: (props) => (
                <CellWithTooltip
                    {...props}
                    valueFormatter={(date) => {
                        return t('formatted-values:formattedDate', {
                            date,
                        })?.replaceAll('&#x2F;', '/');
                    }}
                />
            ),
            width: 100,
            disableSortBy: !isDndLocked,
        },
        {
            id: ChangeTableKeys.startOfContract,
            Header: (props) => (
                <DraggableCell
                    {...props}
                    value={t('change:startOfContract')}
                    draggableId={ChangeTableKeys.startOfContract}
                    isDragAndDropLocked={isDndLocked}
                    hoverPosition={hoverPosition}
                    startPosition={startPosition}
                    draggableIndex={columnPositions[ChangeTableKeys.startOfContract]}
                />
            ),
            accessor: ChangeTableKeys.startOfContract,
            Cell: (props) => (
                <CellWithTooltip
                    {...props}
                    valueFormatter={(date) => {
                        return t('formatted-values:formattedDate', {
                            date,
                        })?.replaceAll('&#x2F;', '/');
                    }}
                />
            ),
            width: 100,
            disableSortBy: !isDndLocked,
        },
        {
            id: ChangeTableKeys.postcode,
            Header: (props) => (
                <DraggableCell
                    {...props}
                    value={t('change:postcode')}
                    draggableId={ChangeTableKeys.postcode}
                    isDragAndDropLocked={isDndLocked}
                    hoverPosition={hoverPosition}
                    startPosition={startPosition}
                    draggableIndex={columnPositions[ChangeTableKeys.postcode]}
                />
            ),
            accessor: ChangeTableKeys.postcode,
            Cell: CellWithTooltip,
            width: 60,
            disableSortBy: !isDndLocked,
        },
        {
            id: ChangeTableKeys.place,
            Header: (props) => (
                <DraggableCell
                    {...props}
                    value={t('change:place')}
                    draggableId={ChangeTableKeys.place}
                    isDragAndDropLocked={isDndLocked}
                    hoverPosition={hoverPosition}
                    startPosition={startPosition}
                    draggableIndex={columnPositions[ChangeTableKeys.place]}
                />
            ),
            accessor: ChangeTableKeys.place,
            Cell: CellWithTooltip,
            width: 95,
            disableSortBy: !isDndLocked,
        },
        {
            id: ChangeTableKeys.street,
            Header: (props) => (
                <DraggableCell
                    {...props}
                    value={t('change:street')}
                    draggableId={ChangeTableKeys.street}
                    isDragAndDropLocked={isDndLocked}
                    hoverPosition={hoverPosition}
                    startPosition={startPosition}
                    draggableIndex={columnPositions[ChangeTableKeys.street]}
                />
            ),
            accessor: ChangeTableKeys.street,
            Cell: CellWithTooltip,
            width: 100,
            disableSortBy: true,
        },
        {
            id: ChangeTableKeys.houseNumber,
            Header: (props) => (
                <DraggableCell
                    {...props}
                    value={t('change:houseNumber')}
                    draggableId={ChangeTableKeys.houseNumber}
                    isDragAndDropLocked={isDndLocked}
                    hoverPosition={hoverPosition}
                    startPosition={startPosition}
                    draggableIndex={columnPositions[ChangeTableKeys.houseNumber]}
                />
            ),
            accessor: ChangeTableKeys.houseNumber,
            Cell: CellWithTooltip,
            width: 50,
            disableSortBy: true,
        },
    ];
};
