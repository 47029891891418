export enum RegistrationResult {
    ContractFound,
    ContractNew,
    NoWkzFound,
    NoFrameworkContractFound,
    MoreThanOneFrameworkContractFound,
    NoAmsContractFound,
    MoreThanOneAmsContractFound,
}

export interface RegistrationItem {
    id: string;
    insuranceConfirmationNumber: string;
    contractNumber: string;
    licenseNumber: string;
    risk: string;
    customer: string;
    result: RegistrationResult;
}

export interface RegistrationDto {
    items: RegistrationItem[];
    total: number;
}
