import { useEffect, useState, useMemo, useCallback, Dispatch, SetStateAction } from 'react';
import { CheckboxItem } from 'src/components/base';
import { TableColumnSettings, TableSettingsDto } from 'src/shared/types';
import { NewRegistrationTableKeys } from '../new-registration-table-config';

const defaultState = [
    {
        title: 'new-registration:evbNr',
        value: NewRegistrationTableKeys.insuranceConfirmationNumber,
        checked: true,
    },
    {
        title: 'new-registration:licenseNumber',
        value: NewRegistrationTableKeys.licenseNumber,
        checked: true,
    },
    {
        title: 'new-registration:customerConnection',
        value: NewRegistrationTableKeys.customerConnection,
        checked: true,
    },
    {
        title: 'new-registration:customer',
        value: NewRegistrationTableKeys.customer,
        checked: true,
    },
    {
        title: 'new-registration:contractNumber',
        value: NewRegistrationTableKeys.contractNumber,
        checked: true,
    },
    {
        title: 'new-registration:risk',
        value: NewRegistrationTableKeys.risk,
        checked: true,
    },
    {
        title: 'new-registration:date',
        value: NewRegistrationTableKeys.date,
        checked: true,
    },
    {
        title: 'new-registration:startOfContract',
        value: NewRegistrationTableKeys.startOfContract,
        checked: true,
    },
    {
        title: 'new-registration:postcode',
        value: NewRegistrationTableKeys.postcode,
        checked: true,
    },
    {
        title: 'new-registration:place',
        value: NewRegistrationTableKeys.place,
        checked: true,
    },
    {
        title: 'new-registration:street',
        value: NewRegistrationTableKeys.street,
        checked: true,
    },
    {
        title: 'new-registration:houseNumber',
        value: NewRegistrationTableKeys.houseNumber,
        checked: true,
    },
    {
        title: 'new-registration:owner',
        value: NewRegistrationTableKeys.owner,
        checked: true,
    },
    {
        title: 'new-registration:result',
        value: NewRegistrationTableKeys.result,
        checked: true,
    },
];

interface UseColumnVisibilityStateReturn {
    visibilitySettingsList: CheckboxItem<TableColumnSettings>[];
    hiddenColumns: string[];
    handleColumnSettingsList: (settings: CheckboxItem[]) => void;
}

interface UseColumnVisibilityStateParams {
    settings: TableSettingsDto | null;
    setSettingsDropdownChanged: Dispatch<SetStateAction<boolean>>;
}

export const useColumnVisibilityState = ({
    settings,
    setSettingsDropdownChanged,
}: UseColumnVisibilityStateParams): UseColumnVisibilityStateReturn => {
    const [visibilitySettingsList, setVisibilitySettingsList] =
        useState<CheckboxItem<TableColumnSettings>[]>(defaultState);

    const handleColumnSettingsList = useCallback((newSettingsList): void => {
        setVisibilitySettingsList(newSettingsList);
        setSettingsDropdownChanged(true);
    }, []);

    useEffect(() => {
        if (settings && Array.isArray(settings.columns)) {
            const newList = visibilitySettingsList.map((settingsItem) => {
                return {
                    ...settingsItem,
                    checked:
                        settings?.columns?.find((item) => item.name === settingsItem.value)
                            ?.isShown || false,
                    data: settings?.columns?.find((item) => item.name === settingsItem.value),
                };
            });
            setVisibilitySettingsList(newList);
            setSettingsDropdownChanged(false);
        }
    }, [settings]);

    const hiddenColumns = useMemo(() => {
        return visibilitySettingsList
            .filter((settingItem) => settingItem.checked === false)
            .map((settingItem) => settingItem.value);
    }, [visibilitySettingsList]);

    return { visibilitySettingsList, hiddenColumns, handleColumnSettingsList };
};
