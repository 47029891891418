import React, { BaseSyntheticEvent } from 'react';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import clsx from 'clsx';
import {
    Popover,
    MenuItem as MaterialMenuItem,
    Typography,
    IconButton,
    useTheme,
} from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import { useNestedMenuStyles } from './nested-menu-styles';
import { MenuItem } from './nested-menu';

export interface NestedMenuItemProps {
    item?: MenuItem;
    isDisabled?: boolean;
    closePopup?: () => void;
}

export const NestedMenuItem = (props: NestedMenuItemProps): JSX.Element => {
    const classes = useNestedMenuStyles();
    const theme = useTheme();

    const { item, isDisabled, closePopup } = props;

    const handleAdditionalAction = (e: BaseSyntheticEvent, cb?: () => void): void => {
        e.stopPropagation();
        if (typeof cb === 'function') {
            cb();
        }
    };

    return (
        <PopupState variant='popover'>
            {(popupState) => {
                return (
                    <div>
                        <MaterialMenuItem
                            disabled={isDisabled}
                            className={classes.menuItem}
                            style={{
                                backgroundColor: item?.isActive
                                    ? theme.palette.info.light
                                    : 'initial',
                            }}
                            {...bindTrigger(popupState)}
                        >
                            <Typography variant='inherit' className={classes.menuItemText}>
                                {item?.title}
                            </Typography>
                            {item?.icon ? (
                                <IconButton
                                    size='small'
                                    onClick={(e: BaseSyntheticEvent) =>
                                        handleAdditionalAction(e, item?.additionalAction)
                                    }
                                    className={clsx(
                                        classes.additionalActive,
                                        item?.isAdditionalActionActive
                                            ? classes.additionalActionActive
                                            : null
                                    )}
                                >
                                    {item?.icon}
                                </IconButton>
                            ) : null}
                            <ArrowForwardIosIcon />
                        </MaterialMenuItem>
                        <Popover
                            {...bindPopover(popupState)}
                            elevation={2}
                            anchorOrigin={{
                                vertical: 'center',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            className={classes.itemsContainer}
                        >
                            {item?.nestedMenu?.map((item: MenuItem) =>
                                !item.nestedMenu ? (
                                    <MaterialMenuItem
                                        disabled={isDisabled}
                                        key={item.title}
                                        className={classes.menuItem}
                                        onClick={() => {
                                            if (typeof item.action === 'function') {
                                                item.action();
                                            }
                                            popupState.close();
                                            if (closePopup) {
                                                closePopup();
                                            }
                                        }}
                                        style={{
                                            backgroundColor: item.isActive
                                                ? theme.palette.info.light
                                                : 'initial',
                                        }}
                                    >
                                        <Typography
                                            variant='inherit'
                                            className={classes.menuItemText}
                                        >
                                            {item.title}
                                        </Typography>
                                        {item.icon ? (
                                            <IconButton
                                                size='small'
                                                onClick={(e: BaseSyntheticEvent) =>
                                                    handleAdditionalAction(e, item.additionalAction)
                                                }
                                                className={clsx(
                                                    classes.additionalActive,
                                                    item.isAdditionalActionActive
                                                        ? classes.additionalActionActive
                                                        : null
                                                )}
                                            >
                                                {item.icon}
                                            </IconButton>
                                        ) : null}
                                    </MaterialMenuItem>
                                ) : (
                                    <NestedMenuItem
                                        key={item.title}
                                        item={item}
                                        isDisabled={isDisabled}
                                    />
                                )
                            )}
                        </Popover>
                    </div>
                );
            }}
        </PopupState>
    );
};
