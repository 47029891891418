import React from 'react';
import { useTranslation } from 'react-i18next';

import { Link } from '@mui/material';

import { applicationVersion } from 'src/environment';

import { useStyle } from './footer-styles';

export const Footer = (): JSX.Element => {
    const classes = useStyle();
    const { t, i18n } = useTranslation(['common']);

    const locale = i18n.language;

    return (
        <div className={classes.footer}>
            <span>
                <Link
                    className={classes.footerLink}
                    target='blank'
                    href={`https://www.hector.global/${locale}/datenschutz-2/`}
                >
                    {t('privacy')}
                </Link>
                <span className={classes.separator}>|</span>
                <Link
                    className={classes.footerLink}
                    target='blank'
                    href={`https://www.hector.global/${locale}/impressum-2/`}
                >
                    {t('imprint')}
                </Link>
                <span className={classes.separator}>|</span>
                ZEUSS Plattform - powered by best data
                <span className={classes.separator}>|</span>
                <span>Version {applicationVersion}</span>
            </span>
        </div>
    );
};
