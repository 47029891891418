import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Tab,
    Tabs,
    TextField,
    Grid,
} from '@mui/material';
import Box from '@mui/system/Box';
import React, { ReactElement, ReactNode } from 'react';
import { CircularProgress } from '../circular-progress/circular-progress';
import XmlEditor from '../xml-editor';
import clsx from 'clsx';
import XmlElement from '../xml-editor/xml-element';
import { usePageStyles } from 'src/pages/pages-styles';
import { useTranslation } from 'react-i18next';
import XmlTree from '../xml-editor/xml-tree';
import LoadingButton from '@mui/lab/LoadingButton';

interface TabPanelProps {
    children?: ReactNode;
    name: string;
    index: number;
    value: number;
}

const TabPanel = ({ children, name, index, value }: TabPanelProps): ReactElement => {
    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={'tabpanel-' + name + '-' + index}
            aria-labelledby={'tab-' + name + '-' + index}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
};

interface RawXmlDialogProps {
    name: string;
    open: boolean;
    xmlTree?: XmlTree;
    xmlString?: string;
    onClose(): void;
    onXmlElementChanged(element: XmlElement): void;
    onUpload(): Promise<void>;
}

export default function RawXmlDialog({
    name,
    open,
    xmlTree,
    xmlString,
    onClose,
    onUpload,
    onXmlElementChanged,
}: RawXmlDialogProps): ReactElement {
    const [selectedTab, selectTab] = React.useState<number>(0);
    const [isUploading, setUploading] = React.useState<boolean>(false);

    const pageStyles = usePageStyles();
    const { t } = useTranslation(['common']);

    const onTabChanged = (event: React.SyntheticEvent, newValue: number) => {
        selectTab(newValue);
    };

    const handleUpload = async () => {
        setUploading(true);
        try {
            await onUpload();
        } finally {
            setUploading(false);
        }
    };

    return (
        <Dialog open={open} fullWidth={true} maxWidth={'xl'} onClose={onClose}>
            <DialogTitle>{name}</DialogTitle>
            <DialogContent style={{ minHeight: '60vh', maxHeight: '60vh' }}>
                <TextField
                    fullWidth
                    multiline
                    maxRows={20}
                    type='text'
                    variant='outlined'
                    inputProps={{ readOnly: true }}
                    value={xmlString}
                />
                {/* {xmlTree === undefined && <CircularProgress isLoading={true} />}
                {xmlTree !== undefined && (
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs
                                value={selectedTab}
                                textColor='secondary'
                                indicatorColor='secondary'
                                onChange={onTabChanged}
                            >
                                <Tab
                                    label={t('viewSource')}
                                    id={'tab-' + name + '-0'}
                                    aria-controls={'tabpanel-' + name + '-0'}
                                />
                                <Tab
                                    label={t('editAsNew')}
                                    id={'tab-' + name + '-1'}
                                    aria-controls={'tabpanel-' + name + '-1'}
                                />
                            </Tabs>
                        </Box>
                        <TabPanel name={name} value={selectedTab} index={0}>
                            <TextField
                                fullWidth
                                multiline
                                maxRows={20}
                                type='text'
                                variant='outlined'
                                inputProps={{ readOnly: true }}
                                value={xmlString}
                            />
                        </TabPanel>
                        <TabPanel name={name} value={selectedTab} index={1}>
                            <XmlEditor xml={xmlTree} onElementChanged={onXmlElementChanged} />
                        </TabPanel>
                    </Box>
                )} */}
            </DialogContent>
            <DialogActions>
                {selectedTab === 1 && (
                    <LoadingButton
                        className={clsx(pageStyles.primaryButton)}
                        variant='contained'
                        loading={isUploading}
                        onClick={handleUpload}
                    >
                        {t('upload')}
                    </LoadingButton>
                )}
                <Button
                    className={clsx(pageStyles.primaryButton)}
                    variant='contained'
                    autoFocus
                    onClick={onClose}
                >
                    {t('close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
