import React, { ReactElement } from 'react';
import { CircularProgress as MuiCircularProgress, useTheme } from '@mui/material';

interface CircularProgressProps {
    isLoading: boolean;
    size?: number;
    color?: string;
}

export const CircularProgress = (props: CircularProgressProps): ReactElement | null => {
    const theme = useTheme();

    const { isLoading, color } = props;

    return isLoading ? (
        <MuiCircularProgress
            style={{ color: color || theme.palette.info.main }}
            size={props.size ? props.size : '2rem'}
        />
    ) : null;
};
