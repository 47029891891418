import React, { ReactElement, ComponentType } from 'react';
import {
    Draggable,
    DraggableStateSnapshot,
    DropAnimation,
    DraggingStyle,
} from 'react-beautiful-dnd';

import { CSSProperties } from '@mui/styles';

function getStyle(style: DraggingStyle, snapshot: DraggableStateSnapshot): CSSProperties {
    if (!snapshot.isDropAnimating) {
        return style as CSSProperties;
    }
    const { curve, duration } = snapshot.dropAnimation as DropAnimation;
    const translate = `translate(0px, 0px)`;
    return {
        ...style,
        transform: `${translate}`,
        transition: `all ${curve} ${duration}s`,
    };
}

interface WithDraggableProps {
    draggableId: string;
    draggableIndex: number;
    isDragAndDropLocked: boolean;
    hoverPosition?: number;
    startPosition?: number;
}

export function withDraggable<T>(
    Component: ComponentType<T>
): ComponentType<T & WithDraggableProps> {
    const ComponentWithDraggable = (props: T & WithDraggableProps): ReactElement => {
        return (
            <Draggable
                disableInteractiveElementBlocking
                isDragDisabled={props.isDragAndDropLocked}
                draggableId={props.draggableId}
                index={props.draggableIndex}
            >
                {(provided, snapshot) => (
                    <>
                        <div
                            ref={provided.innerRef}
                            style={getStyle(
                                provided.draggableProps.style as DraggingStyle,
                                snapshot
                            )}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                        >
                            <Component {...props} />
                        </div>
                    </>
                )}
            </Draggable>
        );
    };
    return ComponentWithDraggable;
}
