import React, { ReactElement } from 'react';
import Typography from '@mui/material/Typography';
import { useHttpClient } from 'src/lib/http-client/use-http-client';
import Link from '@mui/material/Link';
import XmlTree from '../../xml-editor/xml-tree';
import XmlElement from '../../xml-editor/xml-element';
import RawXmlDialog from '../../raw-xml-dialog/raw-xml-dialog';

interface RawLinkCellProps {
    insuranceId: string;
    name: string;
}

export const RawXmlLinkCell = ({ insuranceId, name }: RawLinkCellProps): ReactElement => {
    const [xml, setXml] = React.useState<XmlTree | undefined>(undefined);
    const [rawXml, setRawXml] = React.useState<string | undefined>(undefined);
    const [open, setOpen] = React.useState<boolean>(false);

    const httpClient = useHttpClient();

    const getXml = async (id: string) => {
        const requested = await httpClient.get<string>('insuranceConfirmationSource', {
            axiosConfig: { params: { insuranceConfirmationId: id } },
        });
        return requested;
    };

    const handleOpen = async () => {
        setOpen(true);
        const xml = await getXml(insuranceId);
        setRawXml(xml);
        const tree = new XmlTree(xml);
        setXml(tree);
    };

    const handleChanged = (element: XmlElement) => {
        if (xml === undefined || !xml.elements.has(element.id)) {
            return;
        }
        xml.elements.set(element.id, element);
        setXml(xml);
    };

    const handleUpload = async () => {
        if (xml === undefined) {
            return;
        }
        const rawXml = xml.toString();
        await httpClient.post('uploadInsuranceConfirmation', { xml: rawXml });
    };

    const handleClose = () => {
        setOpen(false);
        setXml(undefined);
        setRawXml(undefined);
    };

    return (
        <div>
            <Link onClick={handleOpen}>
                <Typography noWrap variant='body1'>
                    {name}
                </Typography>
            </Link>
            <RawXmlDialog
                name={name}
                open={open}
                xmlTree={xml}
                xmlString={rawXml}
                onClose={handleClose}
                onUpload={handleUpload}
                onXmlElementChanged={handleChanged}
            />
        </div>
    );
};
