import { MutableRefObject, useMemo, useRef } from 'react';

export class LoadingState {
    constructor(private readonly reference: MutableRefObject<boolean>) {}

    get isLoading(): boolean {
        return this.reference.current;
    }

    set isLoading(value: boolean) {
        this.reference.current = value;
    }
}

export function useLoadingState(): LoadingState {
    const reference = useRef(false);
    const loadingState = useMemo<LoadingState>(() => new LoadingState(reference), []);
    return loadingState;
}
