import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { HttpInfrastructure } from './lib/http-client/axios-http-infrastructure';
import { ConnectivityIndicatorInfrastructure } from './lib/connectivity-indicator/connectivity-indicator-infrastructure';
import { LoginProvider } from './security/login-provider';
import { GlobalStoreProvider } from './shared/contexts/global-store';

ReactDOM.render(
    <React.StrictMode>
        <ConnectivityIndicatorInfrastructure>
            <HttpInfrastructure>
                <GlobalStoreProvider>
                    <LoginProvider>
                        <App />
                    </LoginProvider>
                </GlobalStoreProvider>
            </HttpInfrastructure>
        </ConnectivityIndicatorInfrastructure>
    </React.StrictMode>,
    document.getElementById('root')
);
