import { useMemo } from 'react';
import { AxiosHttpClient, HttpClient } from './http-client';
import { useConnectivityService } from '../connectivity-indicator/use-connectivity-service';
import { useLoadingState } from '../custom-hooks/use-loading-state';
import { useComponentUnloadState } from '../custom-hooks/use-component-unload-state';
import { useAxios } from './axios-http-infrastructure';

export function useHttpClient(): HttpClient {
    const axios = useAxios();
    const loadingState = useLoadingState();
    const componentUnloadState = useComponentUnloadState();

    const httpClient = useMemo<AxiosHttpClient>(
        () => {
            return new AxiosHttpClient(axios, loadingState, componentUnloadState);
        },
        /* The http client must only be created once so that it can safely be used in useEffect hooks.
         * axios, loadingState and componentUnloadState are also only created once, thus it is safe
         * to use an empty array here. Services that might change like t or notification service
         * will be assigned to the 'mutable' part of httpClient.
         */
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    httpClient.connectivityService = useConnectivityService();
    return httpClient;
}
