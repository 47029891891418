import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { THEME_MODES } from 'src/theming/theme-modes';

export const useNestedMenuStyles = makeStyles((theme: Theme) => {
    const isLightMode = THEME_MODES.LIGHT === theme.palette.mode;
    return {
        itemsContainer: {
            display: 'flex',
            flexDirection: 'column',
            '& .MuiPaper-root': {
                backgroundColor: isLightMode
                    ? theme.palette.secondary.contrastText
                    : theme.palette.primary.main,
            },
        },
        button: {
            width: '100%',
            color: theme.palette.primary.contrastText,
            '& .MuiSvgIcon-root': {
                width: 30,
                height: 30,
                color: isLightMode
                    ? theme.palette.secondary.main
                    : theme.palette.secondary.contrastText,
            },
        },
        buttonText: {
            fontSize: '1.8rem',
        },
        menuItem: {
            display: 'flex',
            color: isLightMode
                ? theme.palette.secondary.dark
                : theme.palette.secondary.contrastText,
        },
        menuItemText: {
            flexGrow: 1,
            fontSize: '1.4rem',
        },
        iconButton: {
            '& .MuiSvgIcon-root': {
                width: 20,
                height: 20,
            },
        },
        additionalActive: {
            marginLeft: theme.spacing(2),
            '&:hover': {
                backgroundColor: theme.palette.info.light,
            },
        },
        additionalActionActive: {
            '& .MuiSvgIcon-root': {
                color: theme.palette.info.main,
            },
        },
    };
});
