import { TFunction } from 'react-i18next';
import { ToggleButtonItem } from 'src/components/base';
import { FilterValues } from 'src/shared/constants';

export const generateButtonFilterValues = (t: TFunction): ToggleButtonItem[] => {
    return [
        { title: t('common:filterValues.new'), value: FilterValues.NEW, style: { minWidth: 100 } },
        {
            title: t('common:filterValues.archived'),
            value: FilterValues.ARCHIVED,
            style: { minWidth: 100 },
        },
    ];
};
