import React, { ComponentType, ReactElement } from 'react';
import {
    Droppable,
    DragDropContext,
    DragStart,
    ResponderProvided,
    DragUpdate,
    DropResult,
    Direction,
} from 'react-beautiful-dnd';

interface WithDndContextProps {
    droppableId: string;
    onDragStart: (initial: DragStart, provided: ResponderProvided) => void;
    onDragUpdate: (initial: DragUpdate, provided: ResponderProvided) => void;
    onDragEnd: (result: DropResult, provided: ResponderProvided) => void;
    direction?: Direction;
}

export function withDndContext<T>(
    Component: ComponentType<T>
): ComponentType<T & WithDndContextProps> {
    const ComponentWithDndContext = (props: T & WithDndContextProps): ReactElement => {
        const { onDragEnd, onDragStart, onDragUpdate, droppableId, direction } = props;
        return (
            <DragDropContext
                onDragEnd={onDragEnd}
                onDragStart={onDragStart}
                onDragUpdate={onDragUpdate}
            >
                <Droppable droppableId={droppableId} direction={direction}>
                    {(provided) => (
                        <>
                            <div
                                ref={provided.innerRef}
                                style={{ display: 'flex', height: '100%', width: '100%' }}
                            >
                                <Component {...props} {...provided} />
                            </div>
                            {provided.placeholder}
                        </>
                    )}
                </Droppable>
            </DragDropContext>
        );
    };
    return ComponentWithDndContext;
}
