import React, { ReactElement, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Button, FormControl, FormControlLabel, OutlinedInput, Typography } from '@mui/material';

import PasswordStrengthBar from 'src/lib/password-strength-bar/password-strength-bar';
import { useDebounce } from 'src/lib/custom-hooks/use-debounce';
import { CreatePasswordFormBody } from 'src/shared/types';
import { CircularProgress } from 'src/components/base';

import { createPasswordSchema } from './create-password-validation-schema';
import {
    useStyle,
    useInputStyles,
    useFormControlStyles,
    useFormControlLabelStyles,
} from '../login/login-form-styles';
import { useCreatePassword } from './use-create-password';

const translationNamespaces = ['login', 'password'];

export const CreatePasswordForm = (): ReactElement => {
    const { t } = useTranslation(translationNamespaces);

    const classes = useStyle();
    const inputClasses = useInputStyles();
    const formControlClasses = useFormControlStyles();
    const formControlLabelClasses = useFormControlLabelStyles();

    const [isStrong, setIsStrong] = useState(false);
    const { isLoading, createPassword } = useCreatePassword();

    const {
        watch,
        register,
        handleSubmit,
        formState: { isValid, errors },
    } = useForm<CreatePasswordFormBody>({
        mode: 'onChange',
        resolver: yupResolver(createPasswordSchema),
    });

    const onSubmit = async (vals: CreatePasswordFormBody): Promise<void> => {
        createPassword(vals);
    };

    const password = watch('password');
    const debouncedPassword = useDebounce(password, 400);

    const handleSetStrong = useCallback((value) => {
        setIsStrong(value);
    }, []);
    return (
        <form
            autoComplete='off'
            onSubmit={handleSubmit(onSubmit)}
            className={classes.formContainer}
        >
            <Typography variant='h3' className={classes.formTitle}>
                {t('login:createPassword')}
            </Typography>
            <Typography variant='body1' className={classes.formMessage}>
                {t('login:createPasswordMessage')}
            </Typography>
            <FormControl
                error={Boolean(errors?.password)}
                fullWidth
                classes={formControlClasses}
                variant='outlined'
            >
                <FormControlLabel
                    classes={formControlLabelClasses}
                    control={
                        <OutlinedInput
                            type='password'
                            placeholder={t('login:placeholders.enterNewPassword')}
                            classes={inputClasses}
                            {...register('password')}
                        />
                    }
                    label={t<string>('login:fields.password')}
                    labelPlacement='top'
                />
            </FormControl>
            <PasswordStrengthBar
                className={classes.strengthBar}
                password={debouncedPassword}
                onStrong={handleSetStrong}
                pathToCheckPassword='check-password-strength'
                languageNamespaces={translationNamespaces}
            />
            <FormControl
                error={Boolean(errors?.repeatPassword)}
                fullWidth
                classes={formControlClasses}
                variant='outlined'
            >
                <FormControlLabel
                    classes={formControlLabelClasses}
                    control={
                        <OutlinedInput
                            type='password'
                            placeholder={t('login:placeholders.repeatNewPassword')}
                            classes={inputClasses}
                            {...register('repeatPassword')}
                        />
                    }
                    label={t<string>('login:fields.confirmPassword')}
                    labelPlacement='top'
                />
            </FormControl>
            <Button
                disableElevation
                disabled={!isValid || !isStrong || isLoading}
                startIcon={<CircularProgress isLoading={isLoading} />}
                className={classes.submitButton}
                variant='contained'
                type='submit'
            >
                {t('login:createPassword')}
            </Button>
        </form>
    );
};
