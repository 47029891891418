import React, { ReactElement } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { AppRoutes } from 'src/routing/app-routes';

interface ProtectedRouteProps {
    isPrivate: boolean;
    authorized: boolean | null;
}

export const ProtectedRoute = ({
    authorized,
    isPrivate,
}: ProtectedRouteProps): ReactElement | null => {
    const location = useLocation();
    if (!isPrivate) {
        return <Outlet />;
    }
    if (!authorized) {
        return <Navigate to={AppRoutes.LOGIN} />;
    }

    if (authorized && location.pathname === '/') {
        return <Navigate to={AppRoutes.NEW_REGISTRATION} />;
    }

    return <Outlet />;
};
