import { LoginContext } from './login-context';
import { useAxios } from '../lib/http-client/axios-http-infrastructure';
import { useCallback, useEffect, useState } from 'react';
import { useHttpClient } from '../lib/http-client/use-http-client';
import { LoginSuccessDto } from './login-success-dto';
import { LoggedInUserDto } from './logged-in-user-dto';
import { AuthTokensDto } from './auth-tokens-dto';
import { AxiosError } from 'axios';

const localStorageAccessTokenKey = 'access-token';
const localStorageRefreshTokenKey = 'refresh-token';
const localStorageUserKey = 'user';
const authorizationHeader = 'Authorization';
const createBearerToken = (token: string) => 'Bearer ' + token;

interface LoginProviderProps {
    children: JSX.Element;
}

const storedUser = localStorage.getItem(localStorageUserKey);

export const LoginProvider = ({ children }: LoginProviderProps): JSX.Element => {
    let refreshPromise: Promise<void> | undefined = undefined;

    const [user, setUser] = useState<LoggedInUserDto | null>(
        storedUser ? JSON.parse(storedUser) : null
    );

    const axios = useAxios();
    const httpClient = useHttpClient();

    useEffect(() => {
        axios.interceptors.response.use(
            (response) => response,
            async (error: AxiosError) => {
                if (error?.response?.status === 401) {
                    if (!refreshPromise) {
                        refreshPromise = tryRefreshAuthToken();
                    }

                    if (refreshPromise) {
                        await refreshPromise;
                    }

                    refreshPromise = undefined;

                    const refreshedAccessToken = localStorage.getItem(localStorageAccessTokenKey);

                    if (!user || !refreshedAccessToken || error.config.headers == null) {
                        return;
                    }

                    error.config.headers[authorizationHeader] =
                        createBearerToken(refreshedAccessToken);
                    return axios(error.config);
                }

                return Promise.reject(error);
            }
        );
    }, [axios]);

    const storeLoginData = (dto: LoginSuccessDto) => {
        axios.defaults.headers.common[authorizationHeader] = createBearerToken(
            dto.tokens.accessToken
        );
        localStorage.setItem(localStorageAccessTokenKey, dto.tokens.accessToken);
        localStorage.setItem(localStorageRefreshTokenKey, dto.tokens.refreshToken);
        localStorage.setItem(localStorageUserKey, JSON.stringify(dto.user));
        setUser(dto.user);
    };

    const clearLoginData = useCallback(async () => {
        localStorage.removeItem(localStorageAccessTokenKey);
        localStorage.removeItem(localStorageRefreshTokenKey);
        localStorage.removeItem(localStorageUserKey);
        delete axios.defaults.headers.common[authorizationHeader];
        setUser(null);
    }, []);

    const tryRefreshAuthToken = async (): Promise<void> => {
        const refreshToken = localStorage.getItem(localStorageRefreshTokenKey);
        const accessToken = localStorage.getItem(localStorageAccessTokenKey);

        localStorage.removeItem(localStorageRefreshTokenKey);
        localStorage.removeItem(localStorageAccessTokenKey);

        if (refreshToken == null || accessToken == null) {
            return;
        }

        const request: AuthTokensDto = {
            accessToken,
            refreshToken,
        };

        try {
            const response = await httpClient.post<LoginSuccessDto>('refreshAccessToken', request);
            storeLoginData(response);
        } catch (e) {
            clearLoginData();
        }
    };

    const login = useCallback(
        async (email: string, password: string) => {
            const response = await httpClient.post<LoginSuccessDto>('login', {
                email,
                password,
            });

            storeLoginData(response);
        },
        [axios]
    );

    return (
        <LoginContext.Provider
            value={{
                login,
                logout: clearLoginData,
                user,
            }}
        >
            {children}
        </LoginContext.Provider>
    );
};
