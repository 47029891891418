import { createContext, useContext, Dispatch } from 'react';

import { GlobalStoreActionsType } from 'src/shared/types';

export interface GlobalStoreType {
    needToRefetchGlobalConfirmations: boolean;
    insuranceConfirmationSearch: { value: string };
}

export interface GlobalStoreContextProps {
    store: GlobalStoreType;
    dispatch?: Dispatch<GlobalStoreActionsType>;
}

export const defaultStore: GlobalStoreContextProps = {
    store: {
        needToRefetchGlobalConfirmations: true,
        insuranceConfirmationSearch: { value: '' },
    },
};

export const GlobalStoreContext = createContext<GlobalStoreContextProps>(defaultStore);

export const useGlobalStore = (): GlobalStoreContextProps => {
    const globalStoreContext = useContext(GlobalStoreContext);

    return globalStoreContext;
};
